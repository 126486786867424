<script>
</script>

<div style="width: 100%">

    <div style="display: table; margin: 0 auto">
        <a href="https://dl.hdskins.de/fabric/HDSkins-Fabric-1.18.1.jar">
            <button class="input download-text">Download for Fabric 1.18.1</button>
        </a>
    </div>

</div>

<style>

    p {
        color: white;
        text-align: center;
    }

    .input {
        width: 300px;
        border: none;
        margin-top: 3px;
        text-align: center;
        border-radius: 10px;

        background-color: #d34200;
        cursor: pointer;
    }

    .download-text, .download-text:hover {
        color: white;
        text-decoration: none;
    }

    .input:active {
        background-color: #7f2a04;
    }

</style>
