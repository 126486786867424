<script>

    import {_} from "svelte-i18n";
    import {createEventDispatcher} from 'svelte';

    import {apiHost, renderHost} from "../../config";

    import {getNotificationsContext} from 'svelte-notifications';
    import {banReasons} from "./textureAction";

    const {addNotification} = getNotificationsContext();

    const dispatch = createEventDispatcher();

    export let texture;

    function approve() {
        dispatch("action", {
            result: "APPROVE"
        });

        addNotification({
            text: "Texture was approved",
            position: "bottom-right",
            removeAfter: 4000,
            type: "success"
        });
    }

    function ban(event) {
        const banId = event.target.value;

        dispatch("action", {
            result: "BAN",
            banId: banId
        });

        for (let reason of banReasons) {
            if (reason.id === banId) {
                addNotification({
                    text: "Texture was banned for " + reason.name,
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        }
    }

</script>

<div class="content">
    <button class="approve" on:click={approve}>{$_("admin.texture.approve")}</button>

    <!-- TODO Reset selected value on mount -->
    <select class="ban" on:change={ban}>
        <option value="" disabled selected>{$_("admin.texture.ban")}</option>

        {#each banReasons as banReason}
            <option value="{banReason.id}">{banReason.name}</option>
        {/each}
    </select>

    <button class="render">
        <a href="{renderHost}/textures/skin/{texture.textureId}/full?force" target="_blank">
            Render
        </a>
    </button>

    <button class="render">
        <a href="{apiHost}/textures/{texture.textureId}?force" target="_blank">
            Show
        </a>
    </button>
</div>

<style>

    .content {
    }

    .approve {
        background-color: green;
        cursor: pointer;
    }

    .ban {
        background-color: red;
        cursor: pointer;
    }

    button, select {
        outline: none;
        border: none;
        border-radius: 6px;
        color: white;
    }

    .approve:active {
        background-color: darkgreen;
    }

    .ban:active, option:selected {
        background-color: darkred;
    }

</style>
