<script>
    import Dashboard from "../dashboard/Dashboard.svelte";
    import {apiHost} from "../config.js";

    import Cookies from "js-cookie";

    export let user;

    let notFound = false;
    let userdata;

    fetch(apiHost + "/admin/users/" + user + "?id", {
        headers: {
            "Token": Cookies.get("HDSkins-Login")
        }
    }).then(res => {
        if (res.status === 200) {
            res.text().then(text => {
                fetch(apiHost + "/profile/userinfo?u=" + text, {
                    headers: {
                        "Token": Cookies.get("HDSkins-Login")
                    }
                })
                    .then(res => res.json())
                    .then(json => userdata = json);
            });
        } else {
            notFound = true;
        }
    });
</script>

{#if notFound}
    <h1>User {user} not found</h1>
{:else if userdata !== undefined}
    <Dashboard externalUser={user} loadedData={userdata}/>
{/if}

<style>
    h1 {
        color: white;
        text-align: center;
    }
</style>
