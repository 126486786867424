<script>
    import Modal from "../Modal.svelte";
    import Card from "../dashboard/Card.svelte";

    import {createEventDispatcher} from 'svelte';
    import SuccessAnimation from "./SuccessAnimation.svelte";
    import ErrorAnimation from "./ErrorAnimation.svelte";

    const dispatch = createEventDispatcher();

    export let title = "Information";
    export let type;
    export let text = "";
    export let background;

    function close() {
        dispatch("close", {});
    }
</script>


<Modal
        background="rgba(29, 29, 29, 0.3)"
        innerBackground="{background}"
        width="320px"
        height="200px"
        inner="scale-in-center"
        on:close="{close}"
>
    <div class="modal-content">
        <Card extra="popup-card" background="none" borders={false}>
            <div slot="header">
                <div class="animation">
                    {#if type === "success"}
                        <SuccessAnimation background="{background}"/>
                    {:else if type === "error"}
                        <ErrorAnimation background="{background}"/>
                    {/if}
                </div>

                <h3 class="header-text">{title}</h3>
            </div>

            <p slot="content" class="text">{text}</p>

            <button slot="footer" class="ok-button" on:click={close}>OK</button>
        </Card>
    </div>
</Modal>

<style>
    .animation {
        padding-top: 10px;
    }

    .ok-button {
        border-radius: 6px;
        margin-top: 5px;
        float: right;
    }

    .text {
        text-align: center;
        color: white;
    }

    :global(.popup-card) {
        width: 300px;
        height: 200px;
    }

    .modal-content {
        margin-top: 100px;
    }

    .header-text {
        color: white;
        text-align: center;
        margin: auto;
        padding-bottom: 3px;
    }

    :global(.scale-in-center) {
        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /* ----------------------------------------------
     * Generated by Animista on 2021-10-1 10:41:20
     * Licensed under FreeBSD License.
     * See http://animista.net/license for more info.
     * w: http://animista.net, t: @cssanimista
     * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation scale-in-center
     * ----------------------------------------
     */
    @-webkit-keyframes -global-scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes -global-scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

</style>
