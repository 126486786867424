<script>
    import Card from "../Card.svelte";
    import {_} from "svelte-i18n";

    import {apiHost} from "../../config.js";
    import {authLevel} from "../auth.js";

    import {showPopup} from "../../popup/popup.js";
    import Cookies from "js-cookie";

    import {roles} from "../roles";
    import SkinRender from "../SkinRender.svelte";
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let userdata;
    export let admin;

    let texture = {};
    let pending = false;

    let fileInput;

    function uploadSkin(event) {
        if (pending) {
            return;
        }

        const file = event.target.files[0];

        event.target.value = "";

        fetch(apiHost + "/profile/texture?u=" + $userdata.userId, {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                "TextureType": "SKIN"
            },
            body: file
        }).then(res => {
            pending = false;
            if (res.status === 200) {
                showPopup($_("dashboard.profile.upload.success"), "success");

                updateTexture();
            } else {
                res.text().then(text => showPopup($_("dashboard.profile.upload.failed"), "failed", $_("dashboard.profile.upload.result." + text)));
            }
        }).catch(() => {
            pending = false;
            showPopup($_("dashboard.profile.upload.failed"), "success", $_("error.unknown"));
        });

        pending = true;
    }

    function deleteSkin() {
        if (pending) {
            return;
        }

        fetch(apiHost + "/profile/texture?u=" + $userdata.userId, {
            method: "DELETE",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                "TextureType": "SKIN"
            }
        }).then(res => {
            pending = false;
            if (res.status === 200) {
                showPopup($_("dashboard.profile.delete.success"), "success");

                updateTexture();
            } else {
                showPopup($_("dashboard.profile.delete.failed"), "error", res.status);
            }
        }).catch(() => {
            pending = false;
            showPopup($_("dashboard.profile.delete.failed"), "error", $_("error.unknown"));
        });

        pending = true;
    }

    function updateSlim() {
        if (pending) {
            return;
        }

        const data = {
            slim: !(texture.meta != null && texture.meta.slim)
        };

        fetch(apiHost + "/profile/texture/meta?u=" + $userdata.userId, {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                "TextureType": "SKIN"
            },
            body: JSON.stringify(data)
        }).then(res => {
            pending = false;
            if (res.status === 200) {
                texture.meta = data;
                showPopup($_("dashboard.profile.slim.success." + (data.slim ? "slim" : "classic")), "success");
            } else {
                showPopup($_("dashboard.profile.slim.failed"), "error", res.status);
            }
        }).catch(() => {
            pending = false;
            showPopup($_("dashboard.profile.slim.failed"), "error", $_("error.unknown"));
        });

        pending = true;
    }

    let role;

    function getRole() {
        for (let role of $roles) {
            for (let userRole of $userdata.roles) {
                if (role.id === userRole.id) {
                    return role;
                }
            }
        }

        return {name: userdata.role, color: "white"};
    }

    roles.subscribe(() => role = getRole());
    userdata.subscribe(() => {
        role = getRole();
        updateTexture();
    });

    function updateTexture() {
        if ($userdata.userId === undefined) {
            return;
        }

        fetch(apiHost + "/profile/texture?u=" + $userdata.userId, {
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                "TextureType": "SKIN"
            }
        })
            .then(res => res.json())
            .then(json => texture = json);
    }

    function updatePrivacy() {
        if (pending) {
            return;
        }

        fetch(apiHost + "/profile/texture/privacy/" + !$userdata.privacy + "?u=" + $userdata.userId, {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            pending = false;
            if (res.status === 200) {
                $userdata.privacy = !$userdata.privacy;
                showPopup($_("dashboard.profile.privacy.success." + ($userdata.privacy ? "enabled" : "disabled")), "success");
            } else {
                showPopup($_("dashboard.profile.privacy.failed"), "error", res.status);
            }
        }).catch(() => {
            pending = false;
            showPopup($_("dashboard.profile.slim.failed"), "error", $_("error.unknown"));
        });

        pending = true;
    }

    function updateSlot(delta) {
        if (pending) {
            return;
        }

        const newSlot = $userdata.selectedSlot + delta;
        if (newSlot < 1 || newSlot > $userdata.maxSlots) {
            return;
        }

        pending = true;
        fetch(apiHost + "/profile/slot/" + newSlot + "?u=" + $userdata.userId, {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            pending = false;

            if (res.status === 200) {
                dispatch("updateUser");
            }
        }).catch(() => pending = false);
    }
</script>

<Card extra="profile-card">
    <div slot="header">
        <div class="slot-switch-wrapper">
            {#if (!admin || $authLevel === 3) && $userdata.selectedSlot > 1}
                <button class="slot-switch slot-switch-left no-select" on:click={() => updateSlot(-1)}>«</button>
            {:else}
                <div/>
            {/if}
            <h2 style="text-align: center">{$_("dashboard.profile.title")} {$userdata.selectedSlot !== undefined ? $userdata.selectedSlot + "/" + $userdata.maxSlots : "1"}</h2>
            {#if (!admin || $authLevel === 3) && $userdata.selectedSlot < $userdata.maxSlots}
                <button class="slot-switch slot-switch-right no-select" on:click={() => updateSlot(+1)}>»</button>
            {:else}
                <div/>
            {/if}
        </div>
    </div>

    <div slot="content" class="content">
        {#if texture.id !== undefined}
            <div class="download">
                <a href="{apiHost}/textures/{texture.id}" target="_blank">
                    <img src="/assets/download.svg" alt="Download"/>
                </a>
            </div>

            <div style="display: flex; justify-content: center">
                <SkinRender skinData={texture} width="250" height="450"/>
            </div>
        {/if}

        <div class="userinfo-wrapper">
            <div class="userinfo">
                <div>
                    <div>
                        <span>{$userdata.name}</span>
                    </div>

                    <div>
                        {#if role.name !== undefined}
                            <span style="color: {role.color}">{role.name}</span>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div slot="footer" class="footer">
        {#if !admin || $authLevel >= 3}
            <input
                    style="display: none"
                    type="file"
                    bind:this="{fileInput}"
                    on:change="{event => uploadSkin(event)}"
                    accept="image/png"
            >
            <button
                    class="input {texture.id === undefined ? 'w-50' : 'w-25'}"
                    on:click="{() => fileInput.click()}"
            >
                {$_("dashboard.profile.upload.title")}
            </button>

            <button
                    class="input {texture.id === undefined ? 'w-50' : 'w-25'} slim-{$userdata.privacy ? 'enabled' : 'disabled'}"
                    on:click={updatePrivacy}
                    disabled='{!$userdata.premium}'
                    data-tooltip={!$userdata.premium ? "Premium Feature" : undefined}
            >
                Private
            </button>

            {#if texture.id !== undefined}
                <button
                        class="input w-25 slim-{texture.meta != null && texture.meta.slim ? 'enabled' : 'disabled'}"
                        on:click={updateSlim}
                >
                    {$_("dashboard.profile.slim.title")}
                </button>

                <button class="input w-25 delete-button" on:click={deleteSkin}>
                    {$_("dashboard.profile.delete.title")}
                </button>
            {/if}
        {/if}
    </div>
</Card>

<style>
    .slot-switch-wrapper {
        padding: 0 5px;
        width: 100%;
        display: grid;
        grid-template-columns: 25% 50% 25%;
    }

    .no-select {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    .slot-switch {
        outline: none;
        background: none;
        border: none;
        color: white;
        font-size: x-large;
        padding: 0;

        height: 100%;
        width: 100%;
    }

    .slot-switch:active {
        background: none;
        color: grey;
    }

    .slot-switch-left {

    }

    .slot-switch-right {
        float: right;
    }

    .download {
        position: absolute;
        right: 0;
        margin-top: 5px;
    }

    :global(.profile-card) {
        width: 300px;
        height: 600px;
    }

    h2, span {
        color: white;
    }

    .w-50 {
        width: 50%;
        margin: 0 3px;
    }

    .w-25 {
        width: 25%;
        margin: 0 3px;
    }

    .footer {
        display: flex;
        align-items: center;
        padding: 3px;
    }

    .input {
        margin: 3px;
        border-radius: 6px;
    }

    .content {
        position: relative;
        min-height: 450px;
    }

    .userinfo-wrapper {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .userinfo {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    /* Buttons */

    .input {
        background-color: #2c2f33;
        border-radius: 6px;
        border: 2px white solid;
        color: white;
        outline: none;
    }

    .input:disabled {
        background-color: #2a2c30;
        border-color: gray;
    }

    .input:active {
        background-color: #2c2f33;
        border-color: gray;
    }

    .delete-button {
        border-color: red;
    }

    .delete-button:active {
        border-color: darkred;
    }

    .slim-enabled {
        border-color: green;
    }

    .slim-disabled {
        border-color: red;
    }

    .slim-enabled:active {
        border-color: darkred;
    }

    .slim-disabled:active {
        border-color: darkgreen;
    }
</style>
