<script>

</script>

<div>
    <h1 class="info">You need to be signed in to view this page</h1>
</div>

<style>

    .info {
        text-align: center;
        color: white;
    }

</style>
