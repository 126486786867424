<script>
    import {_} from "svelte-i18n";
    import {links} from "svelte-routing";
</script>

<div class="footer">
    <div class="splitter"/>

    <div class="center social-icons">
        <a href="https://discord.hdskins.de"><img src="/assets/footer/discord.svg" width="24" height="24" class="social-icon"></a>
        <a href="https://twitter.com/HDSkinsDE"><img src="/assets/footer/twitter.svg" width="24" height="24" class="social-icon"></a>
        <a href="ts3server://hdskins.de"><img src="/assets/footer/teamspeak.svg" width="24" height="24" class="social-icon"></a>
        <a href="https://github.com/HDSkins"><img src="/assets/footer/github.svg" width="24" height="24" class="social-icon"></a>
    </div>

    <div use:links class="center links">
        <li>
            <a href="/imprint" class="text">{$_("footer.imprint")}</a>
        </li>
        <li>
            <a href="/privacy" class="text">{$_("footer.privacy")}</a>
        </li>
    </div>

    <div class="center">
        <span class="text copyright">© 2021 HDSkins.de | All rights reserved</span>
    </div>
</div>

<style>
    li {
        display: inline;
        margin: 3px;
    }

    .footer {
        margin-top: auto;
        padding-top: 7px;
        width: 100%;
        height: 130px;
    }

    .links {
        margin-bottom: 3px;
    }

    .splitter {
        margin-left: 20px;
        margin-right: 20px;
        height: 1px;
        background-color: #1D1D1D;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text {
        color: rgb(107, 114, 128);
        padding-left: 5px;
        padding-right: 5px;
    }

    .copyright {
        color: rgb(156, 163, 175);
        margin-top: 10px;
    }

    .social-icons {
        padding: 5px;
    }

    .social-icon {
        padding: 10px;
        cursor: pointer;
    }
</style>
