<script>
    import Card from "../Card.svelte";
    import Time from "svelte-time";
    import {renderHost} from "../../config";
    import CopyToClipboard from "../../../CopyToClipboard.svelte";

    export let userdata;

    let clipboardDiv;
    let copied = false;

    function copy() {
        if (copied) {
            return;
        }

        const app = new CopyToClipboard({
            target: clipboardDiv,
            props: {
                text: $userdata.uuid
            }
        });
        app.$destroy();

        copied = true;
        setTimeout(() => copied = false, 1000);
    }
</script>

<div bind:this={clipboardDiv}></div>

<Card noWrapper={true} extra="status-card">
    <div slot="header" class="header">
        <div>
            <img src="{renderHost}/users/skin/{$userdata.uuid}/face?size=128" width="96" height="96" alt="Player Head">
        </div>

        <div class="userinfo-wrapper">
            <div class="username">{$userdata.name}</div>
            <div class="userinfo-text uuid-label">UUID:
                <span
                        class="uuid"
                        on:click={copy}
                        data-tooltip={copied ? "Copied!" : "Copy to clipboard"}
                >
                    {$userdata.uuid}
                </span>
            </div>

            {#if $userdata.online}
                <div class="userinfo-text online">Online</div>
            {:else}
                <div class="userinfo-text last-online">Last online:
                    <Time timestamp={$userdata.lastOnline} format="DD.MM.YYYY HH:mm"/>
                </div>
            {/if}
        </div>

    </div>

    <div slot="content" class="content">
        <div class="coins-label">HDCoins »
            <img src="/assets/hdcoins.png" width="24" height="24" alt="Coins" class="coins-img">
            <span class="coins">{$userdata.coins} HDCoins</span>
        </div>
        <div class="uuid-label">ID » <span class="uuid">{$userdata.userId}</span></div>
        <div class="uuid-label">Addon-Version » <span class="uuid">{$userdata.addonVersion}</span></div>
        <div class="uuid-label">Minecraft-Version » <span class="uuid">{$userdata.minecraftVersion}</span></div>
        <div class="uuid-label">Client-Version » <span class="uuid">{$userdata.clientVersion}</span></div>
    </div>
</Card>

<style>
    :global(.status-card) {
        width: 450px;
        height: 225px;

        position: relative;
        border-radius: 6px;
        margin: 5px;
    }

    .header {
        background-image: url("/assets/background.png");

        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-top: 6px;
    }

    .username {
        color: white;
        font-size: 1.5rem;
        text-align: right;
        font-weight: bolder;
    }

    .uuid-label {
        color: white;
    }

    .uuid {
        color: #DA4702;
        font-weight: bold;
        cursor: pointer;
    }

    .online {
        color: rgb(52, 211, 153);
    }

    .last-online {
        color: white;
        text-align: right;
    }

    .userinfo-text {
        font-size: 0.875rem;
        font-weight: bold;
        text-align: right;
    }

    .userinfo-wrapper {
        margin: auto 0;
    }

    .coins-label {
        display: flex;
        align-items: center;
        color: white;
    }

    .coins {
        color: rgb(252, 211, 77);
    }

    .coins-img {
        padding: 0 2px;
    }

    h2, span {
        color: white;
    }

    .content {
        position: relative;
        height: 325px;
        overflow-y: auto;
        padding: 5px;
        border: 6px;
    }
</style>
