<script>
    import {navigate} from "svelte-routing";
    import {apiHost} from "../../config";
    import Cookies from "js-cookie";
    import {_} from "svelte-i18n";
    import {getNotificationsContext} from 'svelte-notifications';
    import {getQueryVariable} from "../query";

    const {addNotification} = getNotificationsContext();

    const code = getQueryVariable("code");
    if (code === undefined) {
        navigate("/dashboard");
    } else {
        fetch(apiHost + "/dashboard/discord/oauth/link", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                "discordCode": code
            }
        }).then(res => {
            if (res.status === 200) {
                addNotification({
                    text: $_("dashboard.ext.discord.link.success"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
            } else {
                addNotification({
                    text: $_("dashboard.ext.discord.link.failed"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }

            navigate("/dashboard");
        });
    }
</script>
