<script>
    import About from "./About.svelte";
    import Team from "./Team.svelte";
    import Download from "./Download.svelte";

    import {_} from 'svelte-i18n';
    import DownloadFabric from "./DownloadFabric.svelte";
</script>

<div class="wrapper">

    <h1 class="headline top-headline" id="headline">{$_("home.headline")}</h1>

    <About/>

    <h1 class="headline" id="fabric-download">HDSkins is now also available for Fabric 1.18.1!</h1>
    <DownloadFabric/>

    <h1 class="headline" id="team">{$_("home.team.title")}</h1>
    <Team/>

    <h1 class="headline" id="download">{$_("home.download.title")}</h1>
    <Download/>

</div>

<style>

    .headline {
        color: white;
        text-align: center;
    }

    .top-headline {
        font-size: xxx-large;
    }

    .wrapper {
        max-width: 1800px;
        margin: 0 auto auto;
    }

    :global(.home-page-text) {
        font-size: x-large;
    }

</style>
