<div>
    <canvas
            bind:this={skinCanvas}
            class="skin-canvas"
            on:mouseenter="{() => updateHover(true)}"
            on:mouseleave="{() => updateHover(false)}"
    >
    </canvas>
</div>

<style>
    .skin-canvas {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
</style>

<script>
    import {SkinViewer, createOrbitControls, WalkingAnimation} from "skinview3d";

    import {onMount, onDestroy} from 'svelte';
    import {apiHost} from "../config";

    let walking = false;
    let skinViewer;
    let skinCanvas;

    export let skinData;
    export let width = 350;
    export let height = 600;

    onDestroy(() => skinViewer.dispose());

    onMount(() => {
        skinViewer = new SkinViewer({
            canvas: skinCanvas,
            width: width,
            height: height
        });

        const control = createOrbitControls(skinViewer);
        control.enableRotate = true;
        control.enableZoom = false;

        skinViewer.animations.add(WalkingAnimation).speed = 0.5;

        updateRender();
    });

    function updateHover(hovered) {
        walking = hovered;
        updateRender();
    }

    function updateRender() {
        /*
        if (this.skinData.cloak !== undefined) {
            this.skinViewer.loadCape(`${API_HTTP}/skins/${this.skinData.cloak}/download?force=true`);
        } else {
            this.skinViewer.loadCape(null);
        }
        */
        if (skinData.id !== undefined) {
            const url = `${apiHost}/textures/${skinData.id}?force`;
            if (skinData.meta !== undefined) {
                skinViewer.loadSkin(url, skinData.meta.slim ? "slim" : "classic");
            } else {
                skinViewer.loadSkin(url);
            }
        } else {
            skinViewer.loadSkin(null);
        }
        skinViewer.animations.paused = !walking;
    }
</script>
