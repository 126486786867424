import {addMessages, init, getLocaleFromNavigator} from 'svelte-i18n';

import en from "./lang/en.json";
import de from "./lang/de.json";

addMessages('en', en);
addMessages('de', de);

init({
    fallbackLocale: 'en',
    initialLocale: getLocaleFromNavigator()
});

import App from './App.svelte';

const app = new App({
    target: document.body,
    props: {
        name: 'world'
    },
});

export default app;
