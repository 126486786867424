<script>
    import Card from "../Card.svelte";
    import {_} from "svelte-i18n";

    import {apiHost} from "../../config";
    import Cookies from "js-cookie";

    import {getNotificationsContext} from 'svelte-notifications';
    import {createEventDispatcher} from 'svelte';

    const {addNotification} = getNotificationsContext();
    const dispatch = createEventDispatcher();

    export let discordData;
    export let tsData;
    export let admin;

    const discordUrl = "https://discord.com/api/oauth2/authorize?client_id=835466552773443625&redirect_uri=https%3A%2F%2Fhdskins.de%2Fdashboard%2Fdiscord%2Foauth&response_type=code&scope=identify%20guilds.join";

    let calling = false;

    function refreshDiscord() {
        if (calling) {
            return;
        }
        calling = true;

        fetch(apiHost + "/dashboard/discord/oauth/refresh", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            calling = false;

            if (res.status === 200) {
                refreshUser();

                addNotification({
                    text: $_("dashboard.ext.discord.refresh.success"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
            } else {
                addNotification({
                    text: $_("dashboard.ext.discord.refresh.failed"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }

    function unlinkDiscord() {
        if (calling) {
            return;
        }
        calling = true;

        fetch(apiHost + "/dashboard/discord/oauth/unlink", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            calling = false;

            if (res.status === 200) {
                refreshUser();

                addNotification({
                    text: $_("dashboard.ext.discord.unlink.success"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
            } else {
                addNotification({
                    text: $_("dashboard.ext.discord.unlink.failed"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }

    function linkTeamSpeak() {
        if (calling) {
            return;
        }
        calling = true;

        fetch(apiHost + "/dashboard/teamspeak/link", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            calling = false;

            if (res.status === 200) {
                refreshUser();

                addNotification({
                    text: $_("dashboard.ext.teamspeak.link.success"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
            } else if (res.status === 204) {
                addNotification({
                    text: $_("dashboard.ext.teamspeak.link.noClient"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "warning"
                });
            } else {
                addNotification({
                    text: $_("dashboard.ext.teamspeak.link.failed"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }

    function unlinkTeamSpeak() {
        if (calling) {
            return;
        }
        calling = true;

        fetch(apiHost + "/dashboard/teamspeak/unlink", {
            method: "DELETE",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            calling = false;

            if (res.status === 200) {
                refreshUser();

                addNotification({
                    text: $_("dashboard.ext.teamspeak.unlink.success"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
            } else {
                addNotification({
                    text: $_("dashboard.ext.teamspeak.unlink.failed"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }

    function refreshUser() {
        dispatch("refreshUser")
    }
</script>

<Card extra="ext-services-card">
    <h3 slot="header" class="header-text">{$_("dashboard.ext.title")}</h3>

    <div slot="content">
        <div class="content-wrapper">
            <div class="discord-wrapper"
                 style="background-image: url('/assets/discord_background.png');">
                <div class="discord-avatar-wrapper">
                    {#if discordData !== undefined}
                        <img class="discord-avatar"
                             src="{discordData.avatarUrl}"
                             alt="">
                    {:else}
                        <img class="discord-avatar" src="/assets/footer/discord.svg" alt="Discord">
                    {/if}
                    <div class="discord-profile">
                        <div class="discord-title">Discord</div>
                        {#if discordData !== undefined}
                            <div class="discord-name">{discordData.username}#{discordData.discriminator}</div>
                        {:else}
                            <div class="discord-name">Not linked</div>
                        {/if}
                    </div>
                </div>

                {#if !admin}
                    <div class="discord-buttons">
                        {#if discordData !== undefined}
                            <button class="discord-button" on:click={refreshDiscord}>Refresh</button>
                            <button class="discord-button" on:click={unlinkDiscord}>Unlink</button>
                        {:else}
                            <a style="color: white; text-decoration: none" href={discordUrl}>
                                <button class="discord-button">Link</button>
                            </a>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>

        <div class="splitter"/>

        <div class="content-wrapper">
            <div class="discord-wrapper"
                 style="background-image: url('/assets/discord_background.png');">
                <div class="discord-avatar-wrapper">
                    <img src="/assets/teamspeak.svg" style="color: white" alt="TeamSpeak" height="50"/>

                    <div class="discord-profile">
                        <div class="discord-title">TeamSpeak</div>
                        {#if tsData !== undefined}
                            <div class="discord-name">{tsData.identifier}</div>
                        {:else}
                            <div class="discord-name">Not linked</div>
                        {/if}
                    </div>
                </div>

                {#if !admin}
                    <div class="discord-buttons">
                        {#if tsData !== undefined}
                            <button class="discord-button" on:click={unlinkTeamSpeak}>Unlink</button>
                        {:else}
                            <button class="discord-button" on:click={linkTeamSpeak}>Link</button>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
    </div>
</Card>

<style>
    .content-wrapper {
        display: flex;
        align-items: center;

        width: calc(100% - 10px);
        position: relative;

        margin: 5px;
        margin-top: 10px;
    }

    .header-text {
        color: white;
        text-align: center;
        margin: 3px auto;
    }

    .splitter {
        margin: 10px 5px;
    }

    .discord-wrapper {
        border-radius: 0.375rem;
        padding: 1rem;
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
    }

    .discord-avatar-wrapper {
        display: flex;
        align-items: start;
    }

    .discord-avatar {
        width: 3rem;
        height: 3rem;
        border-radius: 0.5rem
    }

    .discord-profile {
        margin-top: 0;
        margin-left: 1rem;
    }

    .discord-title {
        color: white;
        font-weight: 700;
    }

    .discord-name {
        color: white;
    }

    .discord-buttons {
        margin-top: 0.5rem;
        margin-left: 1.5rem;
        flex-shrink: 0;
    }

    .discord-button {
        align-items: center;
        padding: 0.5rem 1.5rem;
        margin-left: 0.75rem;
        font-weight: 600;
        color: white;
        border-radius: 0.5rem;
        background-color: #DA4702;
        text-transform: none;
    }

    .discord-button:focus {
        outline: none;
    }

    :global(.ext-services-card) {
        width: 600px;
        height: 225px;
    }

    @media screen and (max-width: 1050px) {
        :global(.ext-services-card) {
            width: 500px;
        }
    }

    @media screen and (max-width: 550px) {
        :global(.ext-services-card) {
            width: 350px;
        }
    }
</style>
