<script>
    import TextureAction from "./TextureAction.svelte";
    import Time from "svelte-time";
    import {link} from "svelte-routing";

    import {sendWs} from "../../websocket";

    export let texture;

    function updateTextureState(event) {
        const data = event.detail;

        sendWs("update_texture_state", {
            data: data,
            textureId: texture.textureId
        });
    }
</script>

<div class="content-wrapper">
    <div>
        <!-- TODO Skin Render -->
    </div>

    <div class="texture-action">
        <TextureAction texture={texture} on:action="{updateTextureState}"/>
    </div>

    <div style="padding-top: 3px">
        <span>
            Uploaded <Time timestamp="{texture.uploadTimestamp}" format="DD.MM.YYYY HH:mm"/>
            {texture.uploader !== undefined ? 'by ' + texture.uploader.name : ''}
        </span>
        <br>

        <span>
            {#if texture.ban !== undefined && texture.ban.active}
                Banned: yes
                <br>
                By: {texture.ban.executor.name}
                <br>
                <Time timestamp="{texture.ban.timestamp}" format="DD.MM.YYYY HH:mm"/>
            {:else}
                Banned: no
            {/if}
        </span>

        <div class="users-container">
            {#each texture.users as user}
                <a class="user" href="/admin/user/{user.name}" use:link>{user.name}</a> <br>
            {/each}
        </div>

        <!-- TODO Add skin render of the uploader -->
    </div>
</div>

<style>

    .texture-action {
        float: right;
        padding-top: 3px;
    }

    span {
        color: white;
    }

    .users-container {
        overflow-y: scroll;
        height: 250px;
        background: #1D1D1D;
    }

    .user {
        color: white;
    }

</style>
