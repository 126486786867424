import Cookies from "js-cookie";
import {apiHostWs} from "./config";

export const ws = new WebSocket(apiHostWs + "/ws");

let openHandlers = {};
let messageHandlers = {};

ws.onopen = () => {
    ws.send(Cookies.get("HDSkins-Login"));
};

ws.onmessage = event => {
    const json = JSON.parse(event.data);

    const key = json.key;
    const data = json.data;

    if (key === "login") {
        // Auth success, on failure the connection will just be closed
        Object.values(openHandlers).forEach(handler => handler());
        return;
    }

    Object.values(messageHandlers).forEach(handler => handler(key, data));
};

export function sendWs(key, data) {
    ws.send(JSON.stringify({key: key, data: data}));
}

export function onConnect(key, handler) {
    openHandlers[key] = handler;
    if (ws.readyState === WebSocket.OPEN) {
        handler();
    }
}

export function onMessage(key, handler) {
    messageHandlers[key] = handler;
}