import {writable, get} from 'svelte/store';
import {apiHost} from "../config";
import {authLevel} from "./auth";
import Cookies from "js-cookie";

export const roles = writable([]);

fetch(apiHost + "/roles")
    .then(res => res.json())
    .then(json => roles.set(json));

export const modifiableRoles = writable([]);
let modifiableRolesLoaded = false;

export function getRoleById(id) {
    for (const role of get(roles)) {
        if (role.id === id) {
            return role;
        }
    }

    return null;
}

export function loadModifiableRoles() {
    if (!modifiableRolesLoaded && get(authLevel) === 2) { // Staff only
        fetch(apiHost + "/staff/roles", {
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        })
            .then(res => res.json())
            .then(json => {
                modifiableRolesLoaded = true;
                modifiableRoles.set(json);
            });
    }
}

export function isModifiableRole(roleId) {
    if (get(authLevel) >= 3) {
        return true; // Admins can modify every role
    }

    for (const role of get(modifiableRoles)) {
        if (role === roleId) {
            return true;
        }
    }

    return false;
}