<script>

</script>

<div>
    <h1>Datenschutzerklärung</h1>
    <p>
        <strong>Allgemeine Hinweise</strong><br>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
        personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.
        Ausführliche Informationen zum Thema Datenschutz
        entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
        <br><br>
        <strong>Datenerfassung auf dieser Website</strong>
        <br><br>
        <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong>
        <br>
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können
        Sie dem Impressum dieser Website entnehmen.
        <br><br>
        <strong>Wie erfassen wir Ihre Daten?</strong>
        <br>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B.
        um Daten handeln, die Sie in ein Kontaktformular eingeben.
        <br>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
        IT-Systeme erfasst. Das sind vor allem
        technische Daten (z.&nbsp;B. IP-Adresse, Uhrzeit des Seitenaufrufs, UUID und Name Ihres Minecraft®
        Accounts). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
        betreten oder ein anderes durch uns bereitgestelltes System nutzen.
        <br><br>
        <strong>Wofür nutzen wir Ihre Daten?</strong>
        <br>
        Ihre Daten werden ausschließlich erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
        Jegliche Form der Analyse Ihres Nutzerverhaltens findet nicht statt.
        <br><br>
        <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
        <br>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
        gespeicherten personenbezogenen Daten zu erhalten. Sie
        haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie
        eine Einwilligung zur Datenverarbeitung erteilt haben,
        können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie
        das Recht, unter bestimmten Umständen die Einschränkung
        der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        <br>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
        Impressum angegebenen Adresse an uns wenden.
        <br><br><br>
        <strong>2. Allgemeine Hinweise und Pflichtinformationen</strong>
        <br><br>
        <strong>Datenschutz</strong>
        <br>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
        Ihre personenbezogenen Daten vertraulich und entsprechend
        der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
        <br>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
        Daten sind Daten, mit denen Sie persönlich identifiziert werden
        können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür
        wir sie nutzen. Sie erläutert auch, wie und zu welchem
        Zweck das geschieht.
        <br>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei der Kommunikation per
        E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
        Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
        <br><br><br>
        <strong>Hinweis zur verantwortlichen Stelle</strong>
        <br>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
        <br><br>
        Robin Michalik <br>
        Hauptstraße 28a <br>
        63864 Glattbach <br><br>
        <strong>Kontakt:</strong> <br>
        Telefon: +49 176 56918340 <br>
        E-Mail: <a href="mailto:labymodhdskins@gmail.com">labymodhdskins@gmail.com</a> <br><br>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
        <br><br>
        <strong>Speicherdauer</strong>
        <br>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
        verbleiben Ihre personenbezogenen Daten bei uns, bis der
        Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend
        machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
        werden Ihre Daten gelöscht, sofern wir keinen anderen rechtlich zulässigen&nbsp; Gründe für
        die Speicherung Ihrer personenbezogenen Daten
        haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung
        nach Fortfall dieser Gründe.
        <br><br>
        <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
        <br>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich.
        Sie können eine bereits erteilte Einwilligung jederzeit widerrufen.
        Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        <br><br>
        <strong>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung
            (Art. 21 DSGVO)</strong>
        <br>
        Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 Lit. E oder F DSGVO erfolgt, haben Sie
        jederzeit das Recht, aus Gründen, die sich aus ihrer besonderen
        Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies
        gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
        Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung.
        Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
        personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
        Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
        Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
        <br>
        Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht,
        jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener
        Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit
        solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden
        Ihre personenbezogenen Daten anschliessend nicht mehr zum Zwecke der Direktwerbung verwendet
        (Widerspruch nach Art. 21 Abs. 2 DSGVO).
        <br><br>
        <strong>Beschwerde&shy;recht bei der zuständigen Aufsichts&shy;behörde</strong>
        <br>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
        gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes
        zu. Das Beschwerderecht besteht unbeschadet anderweitiger
        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        <br><br>
        <strong>Recht auf Daten&shy;übertrag&shy;barkeit</strong>
        <br>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
        Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in
        einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung
        der Daten an einen anderen Verantwortlichen verlangen,
        erfolgt dies nur, soweit es technisch machbar ist.
        <br><br>
        <strong>SSL- bzw. TLS-Verschlüsselung</strong>
        <br>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte,
        wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
        Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
        erkennen Sie daran, dass die Adresszeile des Browsers
        von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
        Browserzeile.
        <br>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln,
        nicht von Dritten mitgelesen werden.
        <br>
        <strong>Auskunft, Löschung und Berichtigung</strong>
        <br><br>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
        Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
        Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren
        Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden.
        <br><br>
        <strong>Recht auf Einschränkung der Verarbeitung</strong>
        <br>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        Hierzu können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
        folgenden Fällen:
    </p>
    <ul>
        <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen
            wir in der Regel Zeit, um dies zu überprüfen.
            Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
        </li>
        <br>
        <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können
            Sie statt der Löschung die Einschränkung
            der Datenverarbeitung verlangen.
        </li>
        <br>
        <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
            Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen,
            haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
        </li>
        <br>
        <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
            zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht
            feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </li>
    </ul>
    <br>
    <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
        Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
        Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum
        Schutz der Rechte einer anderen natürlichen oder juristischen
        Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union
        oder eines Mitgliedstaats verarbeitet werden.
    </p>

</div>

<style>
    * {
        color: white;
        text-align: center;
    }
</style>
