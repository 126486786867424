<script>
    import Time from "svelte-time";

    import {_} from "svelte-i18n";
    import Card from "../../dashboard/Card.svelte";
    import {apiHost, renderHost} from "../../config.js";
    import Cookies from "js-cookie";

    import {showPopup} from "../../popup/popup.js";

    import AdminTexture from "./AdminTexture.svelte";

    import {onMessage} from "../../websocket";
    import {onDestroy} from "svelte";

    let selectedTexture = null;
    let textureId = "";
    let textures = [];

    let fileInput;
    let pending = false;

    loadQueryTexture();
    loadHistory();

    onDestroy(() => onMessage("reload_case_history", () => {
    }));

    onMessage("reload_case_history", loadHistory);

    function loadHistory() {
        fetch(apiHost + "/admin/textures/case/history", {
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        })
            .then(res => res.json())
            .then(json => textures = json);
    }

    function loadQueryTexture() {
        const url = window.location.href;
        const index = url.lastIndexOf("#");
        if (index !== -1) {
            const textureId = url.substring(index + 1);
            if (!isNaN(textureId)) {
                requestTextureById(textureId);
            }
        }
    }

    function requestTextureId() {
        requestTextureById(textureId);
    }

    function requestTextureById(id) {
        if (pending) {
            return;
        }

        pending = true;

        fetch(apiHost + "/admin/textures/" + id, {
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        })
            .then(res => {
                textureId = "";

                if (res.status !== 200) {
                    pending = false;
                    return;
                }

                res.json().then(json => {
                    updateSelectedTexture(json);
                    pending = false;
                });
            }).catch(() => pending = false);
    }

    function requestTexture(event) {
        if (pending) {
            return;
        }

        const file = event.target.files[0];

        fetch(apiHost + "/admin/texture", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            },
            body: file
        }).then(res => {
            pending = false;

            if (res.status === 200) {
                res.text().then(textureId => requestTextureById(textureId));
            } else {
                showPopup($_("admin.texture.upload.failed"), "failed");
            }
        }).catch(() => pending = false);

        pending = true;
    }

    function updateSelectedTexture(texture) {
        selectedTexture = texture;

        let url = window.location.href;
        const index = url.lastIndexOf("#");
        if (index !== -1) {
            url = url.substring(0, index);
        }

        const newUrl = url + (texture != null ? "#" + texture.textureId : "");
        window.history.pushState({path: newUrl}, "", newUrl);
    }
</script>

<Card extra="admin-textures-card">
    <div slot="header" class="header">
        {#if selectedTexture != null}
            <img
                    src="/assets/back-button.svg"
                    width="24"
                    height="24"
                    class="back-button"
                    on:click="{() => updateSelectedTexture(null)}"
                    alt="Back"
            >
        {/if}

        {#if selectedTexture != null}
            <h3 class="header-text">{$_("admin.texture.selected.title", {values: {textureId: selectedTexture.textureId}})}</h3>
        {:else}
            <h3 class="header-text">{$_("admin.texture.title")}</h3>
        {/if}
    </div>

    <div slot="content" class="content">
        {#if selectedTexture !== undefined && selectedTexture != null}

            <AdminTexture texture={selectedTexture}/>

        {:else}
            {#each textures as texture}
                <div class="texture" on:click="{() => requestTextureById(texture.textureId)}">
                    <img src="{renderHost}/textures/skin/{texture.textureId}/face?force&size=64" width="32" height="32"
                         alt="Texture">

                    <div class="text">
                        <span>
                            {texture.staff.name} - <Time timestamp="{texture.timestamp}" format="DD.MM.YYYY HH:mm"/>
                        </span>
                    </div>
                </div>
            {/each}
        {/if}
    </div>

    <div slot="footer">
        <input
                style="display: none"
                type="file"
                bind:this="{fileInput}"
                on:change="{requestTexture}"
                accept="image/png"
        >
        <button class="texture-input" on:click="{() => fileInput.click()}">{$_("admin.texture.upload.title")}</button>

        <input
                type="number"
                placeholder="{$_('admin.texture.id')}"
                bind:value="{textureId}"
                class="texture-input"
                on:keyup="{e => e.key === 'Enter' && requestTextureId()}"
        >
    </div>
</Card>

<style>

    .header {
        display: flex;
    }

    .header-text {
        color: white;
        text-align: center;
        margin: auto;
    }

    .back-button {
        cursor: pointer;
        padding: 3px;
    }

    .texture {
        border-bottom: 1px solid gray;
        cursor: pointer;
    }

    .content {
        overflow-y: auto;

        width: 100%;
        height: 450px;
    }

    .text {
        float: right;
    }

    .text > * {
        color: white;
    }

    .texture-input {
        width: calc(100% - 10px);
        margin: 5px;

        background-color: #2c2f33;
        border-radius: 6px;
        border: 2px white solid;
        outline: none;

        color: white;
    }

    .texture-input:active {
        background-color: #2c2f33;
        border-color: gray;
    }

    :global(.admin-textures-card) {
        width: 325px;
        height: 600px;
    }

</style>
