<script>
    import {navigate} from "svelte-routing";
    import {_} from "svelte-i18n";

    import {sendWs, onConnect, onMessage} from "../websocket";
    import {apiHost} from "../config.js";
    import {showPopup} from "../popup/popup.js";
    import Cookies from "js-cookie";

    import AdminCase from "./AdminCase.svelte";
    import AdminTextures from "./texture/AdminTextures.svelte";
    import AdminUsers from "./user/AdminUsers.svelte";
    import {authLevel} from "../dashboard/auth";

    let currentCase;
    let caseOpen = false;
    let caseAvailable;

    let appealAnswerRequired = false;

    let input;

    let openCases = 0;

    function selectUser() {
        const text = input.value;

        fetch(apiHost + "/admin/users/" + encodeURIComponent(text), {
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        })
            .then(res => {
                if (res.status === 200) {
                    res.text().then(username => navigate("/admin/user/" + username));
                } else {
                    showPopup("User not found", "failed");
                }
            });
    }

    onMessage("case", (key, data) => {
        switch (key) {
            case "has_case":
                caseAvailable = data; // data is a boolean
                break;

            case "open_case":
                currentCase = data;
                caseOpen = true;
                caseAvailable = data != null;
                break;

            case "case_count":
                openCases = data;
                break;
        }
    });
    onMessage("has_appeal", (key, data) => {
        if (key === "has_appeal") {
            appealAnswerRequired = data;
        }
    });
    onConnect("case", () => {
        sendWs("has_case", {});
        sendWs("has_appeal", {});
    });

    function nextCase() {
        currentCase = null;

        sendWs("open_case", {});
    }

    function finishCase(action) {
        sendWs("process_case", action.detail);
    }

    function skipCase() {
        sendWs("skip_case");
    }

    function closeCase() {
        currentCase = null;
        caseOpen = false;

        sendWs("cancel_case", {});
    }
</script>

<div class="wrapper">
    <div class="center-wrapper">
        <div>
            <div>
                <input
                        type="text"
                        placeholder="{$_('admin.search')}"
                        class="input"
                        autofocus
                        on:keyup="{e => e.key === 'Enter' && selectUser()}"
                        bind:this="{input}"
                >
            </div>

            <div style="margin-top: 50px;">
                <button
                        class="input btn-input {appealAnswerRequired ? 'answer-required' : ''}"
                        on:click="{() => navigate('/admin/appeals')}"
                >
                    {$_("admin.appeals")}
                </button>
            </div>

            <div>
                <div class="case-wrapper" style="margin-top: 20px;">
                    <button
                            class="input btn-input {caseAvailable ? 'case-open' : 'case-not-open'}"
                            on:click="{nextCase}"
                            disabled="{!caseAvailable}"
                    >
                        {$_("admin.case")} ({openCases})
                    </button>
                </div>
            </div>

            {#if caseOpen && currentCase != null}
                <AdminCase
                        caseInfo={currentCase}
                        on:close={closeCase}
                        on:finish={finishCase}
                        on:skip={skipCase}
                />
            {/if}
        </div>
    </div>

    <div class="texture-info">
        <AdminTextures/>
    </div>

    {#if $authLevel >= 3}
        <div class="users-list">
            <AdminUsers/>
        </div>
    {/if}
</div>

<style>
    h3 {
        color: white;
    }

    .center-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 50px auto auto;
    }

    .texture-info {
        position: absolute;
    }

    .users-list {
        position: absolute;
        right: 0;
    }

    .wrapper {
        display: flex;
        height: 100%;
        min-height: 600px;
        position: relative;
    }

    .input {
        outline: none;
        border-radius: 6px;
        background-color: rgb(17, 24, 39);
        color: white;

        width: 250px;
    }

    .input:active {
        background-color: rgb(17, 24, 39);
    }

    .btn-input {
        cursor: pointer;
    }

    .answer-required {
        background-color: red;
    }

    ::placeholder {
        color: rgb(156, 163, 175);
    }

    ::-ms-input-placeholder {
        color: rgb(156, 163, 175);
    }

    .case-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .case-open {
        background-color: red;
    }

    .case-not-open {
        background-color: rgb(17, 24, 39);
    }

    .case-open:active {
        background-color: darkred;
    }

    @media screen and (max-width: 1100px) {
        .wrapper {
            display: block;
        }

        .texture-info, .users-list {
            position: relative;
            display: flex;
            justify-content: center;
        }
    }

</style>
