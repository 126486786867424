<script>
    import Card from "../Card.svelte";
    import {_} from "svelte-i18n";
    import {getRoleById, isModifiableRole, loadModifiableRoles, modifiableRoles, roles} from "../roles";
    import {apiHost} from "../../config";
    import Cookies from "js-cookie";
    import {authLevel} from "../auth";
    import Time from "svelte-time";
    import {createEventDispatcher} from 'svelte';
    import {getNotificationsContext} from 'svelte-notifications';

    const dispatch = createEventDispatcher();
    const {addNotification} = getNotificationsContext();

    export let userdata;

    let selectedRole;
    let days = 0;

    loadModifiableRoles();

    function addRole() {
        if (selectedRole === undefined) {
            return;
        }

        const role = getRoleById(selectedRole);
        const headers = {
            "Token": Cookies.get("HDSkins-Login")
        };
        if (days !== 0) {
            headers.time = days * 24 * 60 * 60 * 1000;
        }

        fetch(apiHost + "/admin/users/" + $userdata.userId + "/role/" + role.id, {
            method: "POST",
            headers: headers
        }).then(res => {
            if (res.status === 200) {
                addNotification({
                    text: "Successfully added role " + role.name,
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
                dispatch("refreshUser");
            } else {
                addNotification({
                    text: "Failed to add role " + role.name,
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }

    function removeRole(roleId) {
        const role = getRoleById(roleId);
        fetch(apiHost + "/admin/users/" + $userdata.userId + "/role/" + role.id, {
            method: "DELETE",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            if (res.status === 200) {
                addNotification({
                    text: "Successfully removed role " + role.name,
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
                dispatch("refreshUser");
            } else {
                addNotification({
                    text: "Failed to remove role " + role.name,
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }

    function hasRole(roleId) {
        for (const role of $userdata.roles) {
            if (role.id === roleId) {
                return true;
            }
        }

        return false;
    }
</script>

{#if $userdata.roles !== undefined}
    <Card extra="role-card">
        <div slot="header" style="display: flex; justify-content: center">
            <h2 style="text-align: center">{$_("dashboard.profile.role")}</h2>
        </div>

        <div slot="content" class="content">
            {#each $userdata.roles as role}
                <div class="role-wrapper">
                    <div>
                        <span style="color: {getRoleById(role.id).color}">{getRoleById(role.id).name}</span>
                    </div>

                    <div style="display: flex; justify-content: center">
                        {#if role.timeout === -1}
                            <span style="color: white">permanent</span>
                        {:else}
                        <span style="color: white">
                            <Time timestamp="{role.timeout}" format="DD.MM.YYYY HH:mm"/>
                        </span>
                        {/if}
                    </div>

                    {#if $authLevel >= 3 || $modifiableRoles.length !== 0}
                        {#if isModifiableRole(role.id)}
                            <div>
                                <a class="delete-role" on:click={() => removeRole(role.id)}>
                                    <img src="/assets/redx.svg" alt="Delete" width="16" height="16">
                                </a>
                            </div>
                        {/if}
                    {/if}
                </div>
            {/each}
        </div>

        <div slot="footer">
            {#if $authLevel >= 3 || $modifiableRoles.length !== 0}
                <div class="footer">
                    <select bind:value={selectedRole} class="input">
                        {#each $roles as role}
                            {#if isModifiableRole(role.id) && !hasRole(role.id)}
                                <option value={role.id} style="color: {role.color} !important">
                                    {role.name}
                                </option>
                            {/if}
                        {/each}
                    </select>

                    <input type="number" class="input" min="0" bind:value={days}>

                    <a on:click={addRole}>
                        <img src="/assets/plus.png" width="32" height="32" class="create-button" alt="Create"/>
                    </a>
                </div>
            {/if}
        </div>
    </Card>
{/if}

<style>
    :global(.role-card) {
        width: 440px;
        height: 250px;
    }

    .content {
        position: relative;
        height: 150px;
        overflow-y: auto;
        padding: 5px;
        border: 6px;
        margin: 2px;
    }

    .input {
        background-color: #2c2f33;
        border-radius: 6px;
        border: 2px white solid;
        color: white;
        outline: none;
    }

    .input:active {
        background-color: #2c2f33;
        border-color: gray;
    }

    .create-button {
        float: right;
    }

    .role-wrapper {
        width: 98%;
        height: 25px;

        margin: 3px 0;
        padding: 5px;

        border-radius: 6px;
        background-image: url("/assets/background.png");
        overflow-x: hidden;

        column-count: 3;
    }

    .delete-role {
        float: right;
    }

    .footer {
        padding: 10px;
    }

    h2 {
        color: white;
    }
</style>
