import {apiHost} from "../../config";
import Cookies from "js-cookie";

export let banReasons = [];

fetch(apiHost + "/reasons", {
    headers: {
        "Token": Cookies.get("HDSkins-Login")
    }
}).then(res => res.json()).then(json => banReasons = json);
