<script>

    import {_} from "svelte-i18n";
    import AppealMessage from "./AppealMessage.svelte";
    import Time from "svelte-time";

    import {apiHost} from "../../config";
    import {showPopup} from "../../popup/popup.js";
    import Cookies from "js-cookie";

    const searchParams = new URL(window.location.href).searchParams;
    const open = searchParams.get("open") != null;
    const id = open ? searchParams.get("open") : searchParams.get("id");
    if (id == null) {
        navigate("/dashboard");
    }

    function sendMessage(message) {
        fetch(apiHost + "/appeal/" + id + "/message", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                message: encodeURIComponent(message)
            }
        })
            .then(res => {
                if (res.status === 200) {
                    window.location.reload();
                    return;
                }

                showPopup("Failed to send your message", "failed", res.status);
            });
    }

    let messages = [];
    let banInfo;

    let selectedTemplate;
    let textInput;

    let messageInput;

    fetch(apiHost + "/appeal/" + id, {
        headers: {
            "Token": Cookies.get("HDSkins-Login")
        }
    })
        .then(res => res.json())
        .then(json => {
            messages = json.messages;

            fetch(apiHost + "/punishments/" + json.punishmentId, {
                headers: {
                    "Token": Cookies.get("HDSkins-Login")
                }
            })
                .then(res => res.json())
                .then(json => banInfo = json);
        });

    const templates = [
        {
            name: "Test1",
            sub: [
                {
                    name: "asdf",
                    text: "amsdlfkajsdölakwj3tölkawejtadsf"
                },
                {
                    name: "xyz",
                    text: "92378tslakghdsf"
                }
            ]
        },
        {
            name: "Test2",
            sub: [
                {
                    name: "asdf2",
                    text: "amsdlfkajsdölakwj3tölkawejtadsf"
                },
                {
                    name: "xyz2",
                    text: "92378tslakghdsf"
                }
            ]
        }
    ];

</script>

<div>
    <div class="header">
        <h1>{$_("appeal.title")}</h1>

        {#if banInfo !== undefined}
            <div class="ban-info">
                <span>{banInfo.reason}</span>
                <br>
                <span><Time timestamp="{banInfo.from}" format="DD.MM.YYYY HH:mm"/></span>
                <br>
                <span><Time timestamp="{banInfo.until}" format="DD.MM.YYYY HH:mm"/></span>
            </div>
        {/if}
    </div>

    <div>

        <div>
            {#each messages as message}
                <AppealMessage message={message}/>
            {/each}
        </div>

        <div class="template-wrapper">
            <p class="template-title">{$_("appeal.template.title")}</p>

            <div class="template-list">
                {#each templates as template}
                    <div
                            class="
                            template-name
                            {selectedTemplate !== undefined && selectedTemplate.name === template.name ? 'template-name-selected' : ''}
                        "
                            on:click={() => selectedTemplate = template}
                    >
                        <span>{template.name}</span>
                    </div>
                {/each}
            </div>

            <div class="template-list">
                {#if selectedTemplate !== undefined}
                    {#each selectedTemplate.sub as subTemplate}
                        <div
                                class="template-name"
                                on:click={() => messageInput = subTemplate.text}
                        >
                            <span>{subTemplate.name}</span>
                        </div>
                    {/each}
                {/if}
            </div>
        </div>

        <div class="input-wrapper">
            <textarea
                id="answer-input"
                type="text"
                bind:value={messageInput}
                placeholder="{$_("appeal.answer")}"
                class="input"
                on:keyup="{e => e.ctrlKey && e.key === 'Enter' && sendMessage(messageInput)}"
            />

            <button
                class="submit"
                on:click={() => sendMessage(messageInput)}
            >
                <img src="/assets/send-message.png" class="submit-icon" alt="Send">
            </button>
        </div>

    </div>

</div>

<style>
    h1 {
        color: white;
    }

    .template-wrapper {
        margin-top: 5px;
        border-top: 1px gray solid;
    }

    .template-title {
        text-align: center;
        font-size: larger;
        color: white;
    }

    .input-wrapper {
        display: flex;

        margin-top: 10px;
        padding-top: 10px;
    }

    .submit {
        width: 50px;
        height: 250px;

        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        cursor: pointer;
    }

    .submit-icon {
        height: 30px;
    }

    .input {
        width: -moz-calc(100% - 50px);
        width: -webkit-calc(100% - 50px);
        width: calc(100% - 50px);
        height: 250px;
        resize: none;

        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .input:focus {
        outline: none;
    }

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .ban-info {
        position: absolute;
        right: 0;
    }

    .ban-info > span {
        color: white;
        float: right;
    }

    .template-list {
        display: flex;
        justify-content: center;
    }

    .template-name {
        border-radius: 5px;
        background-color: #11111199;
        margin: 5px;
        padding: 5px;
        cursor: pointer;
    }

    .template-name-selected {
        background-color: #33448899;
    }

    .template-name > span {
        color: white;

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    @media screen and (max-width: 200px) {
        .header {
            display: block;
        }

        .ban-info {
            float: none;
        }
    }
</style>
