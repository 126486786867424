<script>
    import {_} from 'svelte-i18n';

    import SlideIn from "../animation/SlideIn.svelte";
</script>

<div>

    <div class="content-wrapper">

        <div class="text-wrapper">
            <SlideIn direction="left">
                <p class="home-page-text">{$_("home.about.text")}</p>
            </SlideIn>
        </div>

        <div class="image-wrapper">
            <img src="/assets/settings.webp" alt="Banner" class="image"/>
        </div>

    </div>

</div>

<style>

    p {
        color: white;
        text-align: center;
    }

    .content-wrapper {
        display: flex;
    }

    .text-wrapper {
        width: 80%;
        padding: 10px;

        display: flex;
        align-items: center;
    }

    .image {
        width: 80%;
    }

    @media screen and (max-width: 1100px) {
        .content-wrapper {
            display: block;
        }

        .image {
            width: 90%;
        }

        .image-wrapper {
            display: flex;
            justify-content: center;
        }

        .text-wrapper {
            width: 100%;
        }
    }

</style>
