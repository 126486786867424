<script>
    import {apiHost} from "../../config.js";
    import {navigate} from "svelte-routing";
    import Cookies from "js-cookie";
    import {updateAuthLevel} from "../auth";

    export let location;

    let response;

    fetch(apiHost + "/auth/login" + location.search, {
        method: "POST"
    })
        .then(res => {
            if (res.status === 200) {
                res.text().then(token => {
                    Cookies.set("HDSkins-Login", token, {
                        domain: ".hdskins.de",
                        expires: new Date("9999-12-31T00:00:00")
                    });
                    navigate("/dashboard");
                    updateAuthLevel();
                });
            } else {
                response = status;
            }
        })
        .catch(() => response = 0);
</script>

<div>
    {#if response === undefined}
        <h1 class="pending">Please wait while you're getting logged in...</h1>
    {:else}
        <h1 class="failed">Failed to log you in, please contact the support ({response})</h1>
    {/if}
</div>

<style>

    .pending {
        color: white;
    }

    .failed {
        color: red;
    }

</style>
