<script>
    import {_} from "svelte-i18n";
    import Card from "../../dashboard/Card.svelte";
    import {apiHost, renderHost} from "../../config";
    import Cookies from "js-cookie";
    import {link} from "svelte-routing";
    import InfiniteScroll from "./InfiniteScroll.svelte";
    import {onMount} from "svelte";
    import {getRoleById} from "../../dashboard/roles";

    let users = [];
    let newBatch = [];
    let roles = {};
    let page = 0;

    function nextPage() {
        ++page;
        loadUsers();
    }

    async function loadUsers() {
        const response = await fetch(apiHost + "/admin/users/online?offset=" + page, {
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        });
        newBatch = await response.json();

        newBatch.forEach(user => {
            fetch(apiHost + "/users/" + user.uuid + "/labyrole")
                .then(res => res.text())
                .then(role => roles[user.uuid] = role !== "0" ? role : "user");
        });
    }

    onMount(() => loadUsers());

    $: users = [
        ...users,
        ...newBatch
    ];
</script>

<Card extra="admin-users-card">
    <div slot="header" class="header">
        <h3 class="header-text">{$_("admin.user.title")}</h3>
    </div>

    <div slot="content" class="content">
        {#each users as user}
            <div class="texture">
                <img src="{renderHost}/users/skin/{user.uuid}/face?size=32"
                     width="32"
                     height="32"
                     alt="Texture">
                <a class="user-name"
                   style="color: {getRoleById(user.role).color}"
                   href="/admin/user/{user.name}"
                   use:link
                >
                    {user.name}
                </a>

                <div style="width: 100%">
                    {#if roles[user.uuid] !== undefined}
                        <a href="https://laby.net/@{user.name}" target="_blank">
                            <img src="https://icon.hdskins.de/groups/{roles[user.uuid]}.png" width="32" height="32" class="laby-role">
                        </a>
                    {/if}
                </div>
            </div>
        {/each}
        <InfiniteScroll
                hasMore={users.length}
                threshold={100}
                on:loadMore={nextPage}
        />
    </div>
</Card>

<style>

    .header {
        display: flex;
    }

    .header-text {
        color: white;
        text-align: center;
        margin: auto;
    }

    .texture {
        border-bottom: 1px solid gray;
        padding: 3px 0;

        display: flex;
        align-items: center;
    }

    .content {
        overflow-y: auto;

        width: 100%;
        height: 560px;
    }

    .laby-role {
        float: right;
    }

    .text > * {
        color: white;
    }

    .user-name {
        color: white;
        vertical-align: center;
        margin-left: 3px;
    }

    :global(.admin-users-card) {
        width: 325px;
        height: 600px;
    }

</style>
