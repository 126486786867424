<script>
    import {navigate} from "svelte-routing";
    import {apiHost} from "../../config";
    import Cookies from "js-cookie";
    import {_} from "svelte-i18n";
    import {getNotificationsContext} from 'svelte-notifications';

    import {getQueryVariable} from "../query";
    import {updateAuthLevel} from "../auth";

    const {addNotification} = getNotificationsContext();

    const code = getQueryVariable("code");
    if (code === undefined) {
        window.location.href = "/";
    } else {
        fetch(apiHost + "/dashboard/labymod/oauth", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                "code": code
            }
        }).then(res => {
            if (res.status === 200) {
                res.text().then(token => {
                    Cookies.set("HDSkins-Login", token, {
                        domain: ".hdskins.de",
                        expires: new Date("9999-12-31T00:00:00")
                    });

                    addNotification({
                        text: $_("login.success"),
                        position: "bottom-right",
                        removeAfter: 4000,
                        type: "success"
                    });

                    updateAuthLevel().then(() => navigate("/dashboard"));
                });
            } else if (res.status === 409) {
                addNotification({
                    text: $_("login.failed.notFound"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "warning"
                });
            } else {
                addNotification({
                    text: $_("login.failed.error"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });

                navigate("/");
            }
        });
    }
</script>
