<script>

    import ProfileCard from "./cards/ProfileCard.svelte";
    import ExternalServicesCard from "./cards/ExternalServicesCard.svelte";
    import BanInfoCard from "./ban/BanInfoCard.svelte";
    import {apiHost} from "../config";
    import Cookies from "js-cookie";

    import {onMount} from "svelte";

    import {writable} from 'svelte/store';
    import MetaCard from "./cards/MetaCard.svelte";
    import StatusCard from "./cards/StatusCard.svelte";
    import RoleCard from "./cards/RoleCard.svelte";
    import CommentCard from "./cards/CommentCard.svelte";

    export let externalUser;
    export let loadedData;

    const userdata = writable({
        roles: [],
        uuid: "loading...",
        name: "loading..."
    });

    onMount(() => {
        if (loadedData !== undefined) {
            userdata.set(loadedData);
        } else {
            loadUserData();
        }
    });

    function loadUserData() {
        let url = apiHost + "/profile/userinfo";
        if ($userdata.userId !== undefined) {
            url += "?u=" + $userdata.userId;
        }

        fetch(url, {
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        })
            .then(res => res.json())
            .then(json => userdata.set(json));
    }

</script>

<div class="dashboard">
    <div class="wrapper">
        <div class="card-container">
            <div class="card">
                <ProfileCard admin="{externalUser !== undefined}" userdata="{userdata}" on:updateUser={loadUserData}/>
            </div>
            <div>
                <div class="card">
                    <ExternalServicesCard
                            discordData={$userdata.discord}
                            tsData={$userdata.teamspeak}
                            admin="{externalUser !== undefined}"
                            on:refreshUser={loadUserData}
                    />
                </div>
                <div class="card">
                    <BanInfoCard
                            userdata={userdata}
                            admin="{externalUser !== undefined}"
                            on:refreshUser={loadUserData}
                    />
                </div>
            </div>
            <div class="card">
                {#if $userdata.meta !== undefined && externalUser !== undefined}
                    <MetaCard userdata={userdata}/>
                {/if}
            </div>

            {#if externalUser !== undefined}
                <div>
                    <div class="card">
                        <StatusCard userdata={userdata}/>
                    </div>
                    <div class="card">
                        <RoleCard userdata={userdata} on:refreshUser={loadUserData}/>
                    </div>
                    <div class="card">
                        <CommentCard userdata={userdata}/>
                    </div>
                </div>
            {/if}
        </div>
    </div>
</div>

<style>

    h1 {
        color: white;
    }

    .dashboard {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-container {
        display: flex;
    }

    .card {
        padding: 5px;
    }

    @media screen and (max-width: 925px) {
        .card-container {
            display: block;
        }

        .card {
            display: flex;
            justify-content: center;
        }

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

</style>
