import {writable} from 'svelte/store';

export const popupTitle = writable(undefined);
export const popupType = writable(undefined);
export const popupText = writable(undefined);
export const popupBackground = writable(undefined);

export function showPopup(title, type, text = "", background = "rgb(29, 29, 29)") {
    popupTitle.set(title);
    popupType.set(type);
    popupText.set(text);
    popupBackground.set(background);
}

export function hidePopup() {
    showPopup(undefined, undefined, undefined, undefined);
}
