<script>

    import {_} from "svelte-i18n";

    import AppealMessage from "./AppealMessage.svelte";
    import Time from "svelte-time";

    import {navigate} from "svelte-routing";

    import {apiHost} from "../../config";
    import {showPopup} from "../../popup/popup.js";
    import Cookies from "js-cookie";

    const searchParams = new URL(window.location.href).searchParams;
    const open = searchParams.get("open") != null;
    const id = open ? searchParams.get("open") : searchParams.get("id");
    if (id == null) {
        navigate("/dashboard");
    }

    let messageInput;

    function sendMessage(message) {
        fetch(apiHost + "/appeal/" + (open ? "open/" + id : id + "/message"), {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                message: encodeURIComponent(message)
            }
        })
            .then(res => {
                if (res.status === 200) {
                    window.location.reload();
                    return;
                }

                showPopup("Failed to send your message", "failed", res.status);
            });
    }

    let messages = [];
    let banInfo;

    if (!open) {
        fetch(apiHost + "/appeal/" + id, {
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        })
            .then(res => res.json())
            .then(json => {
                messages = json.messages;

                fetch(apiHost + "/punishments/" + json.punishmentId, {
                    headers: {
                        "Token": Cookies.get("HDSkins-Login")
                    }
                })
                    .then(res => res.json())
                    .then(json => banInfo = json);
            });
    }

</script>

<div>
    <div class="header">
        <h1>{$_("appeal.title")}</h1>

        {#if banInfo !== undefined}
            <div class="ban-info">
                <span>{banInfo.reason}</span>
                <br>
                <span><Time timestamp="{banInfo.timestamp}" format="DD.MM.YYYY HH:mm"/></span>
                <br>
                <span>
                    {#if banInfo.timeout != -1}
                        <Time timestamp="{banInfo.timeout}" format="DD.MM.YYYY HH:mm"/>
                    {:else}
                        permanent
                    {/if}
                </span>
            </div>
        {/if}
    </div>

    <div>

        <div>
            {#each messages as message}
                <AppealMessage message={message}/>
            {/each}
        </div>

        <div class="input-wrapper">
            <textarea
                id="answer-input"
                type="text"
                placeholder="{$_("appeal.answer")}"
                class="input"
                maxlength="5000"
                bind:value={messageInput}
                on:keyup="{e => e.ctrlKey && e.key === 'Enter' && sendMessage(messageInput)}"
            />

            <button
                class="submit"
                on:click={() => sendMessage(messageInput)}
            >
                <img src="/assets/send-message.png" class="submit-icon" alt="Send">
            </button>
        </div>

    </div>

</div>

<style>
    h1 {
        color: white;
    }

    .input-wrapper {
        display: flex;

        border-top: 1px gray solid;

        margin-top: 10px;
        padding-top: 10px;
    }

    .submit {
        width: 50px;
        height: 250px;

        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        cursor: pointer;
    }

    .submit-icon {
        height: 30px;
    }

    .input {
        width: -moz-calc(100% - 50px);
        width: -webkit-calc(100% - 50px);
        width: calc(100% - 50px);
        height: 250px;
        resize: none;

        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .input:focus {
        outline: none;
    }

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .ban-info {
        position: absolute;
        right: 0;
    }

    .ban-info > span {
        color: white;
        float: right;
    }

    @media screen and (max-width: 200px) {
        .header {
            display: block;
        }

        .ban-info {
            float: none;
        }
    }
</style>
