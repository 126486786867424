<script>
    import Time from "svelte-time";

    import Card from "../dashboard/Card.svelte";
    import Modal from "../Modal.svelte";

    import {apiHost, renderHost} from "../config";

    import TextureAction from "./texture/TextureAction.svelte";

    export let caseInfo;

    import {createEventDispatcher} from 'svelte';
    import {authLevel} from "../dashboard/auth";

    const dispatch = createEventDispatcher();

    function forwardClose(event) {
        dispatch("close", event.detail);
    }

    function processResult(action) {
        dispatch("finish", action.detail);
    }

    function skipCase() {
        dispatch("skip");
    }
</script>

<Modal width="705px" height="705px" on:close="{forwardClose}">
    <div class="modal-content">
        <Card extra="admin-case-card" background="none">

            <div slot="header" class="header">
                <span class="right"><Time timestamp="{caseInfo.timestamp}" format="DD.MM.YYYY HH:mm"/></span>

                <div class="user">
                    {#if caseInfo.openerId != null}
                        <img src="{renderHost}/users/skin/{caseInfo.openerId}/face?size=64" alt="Head" width="48" height="48" style="padding-right: 10px">
                        {#if caseInfo.type === "REPORT"}
                            <span>Reported by: {caseInfo.openerName}</span>
                        {:else if caseInfo.type === "UPLOAD"}
                            <span>Uploaded by: {caseInfo.openerName}</span>
                        {/if}
                    {:else}
                        <img src="/favicon.png" alt="Head" width="48" height="48">
                    {/if}
                </div>
            </div>

            <div slot="content" class="content-wrapper">
                <img src="{apiHost}/textures/{caseInfo.textureId}?force" width="500" alt="Texture" class="texture">
            </div>

            <div slot="footer" class="footer">
                <TextureAction texture={caseInfo} on:action="{processResult}"/>

                {#if $authLevel < 3}
                    <!-- Only shown if not an admin -->
                    <button on:click={skipCase}>Skip case</button>
                {/if}
            </div>

        </Card>
    </div>
</Modal>

<style>

    :global(.admin-case-card) {
        width: 700px;
        height: 700px;
    }

    .modal-content {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 30px;
    }

    .header {
        position: relative;
        padding: 5px;
    }

    .footer {
        padding: 5px;
    }

    .right {
        position: absolute;
        right: 0;
    }

    span {
        color: white;
    }

    .content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user {
        display: flex;
        align-items: center;
    }

    .texture {
        padding: 15px;
    }

</style>
