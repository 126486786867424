<script>

</script>

<div class="content-wrapper">
    <h1>Page not found</h1>
</div>

<style>

    h1 {
        color: white;
    }

    .content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>