<script>
    import Card from "../Card.svelte";
    import {_} from "svelte-i18n";
    import {apiHost} from "../../config";
    import Cookies from "js-cookie";
    import Time from "svelte-time";
    import {getNotificationsContext} from 'svelte-notifications';
    import {onMount} from "svelte";

    const {addNotification} = getNotificationsContext();

    export let userdata;

    let comments = [];

    let commentInput;

    onMount(() => {
        userdata.subscribe(() => loadComments());
        loadComments()
    });

    function loadComments() {
        fetch(apiHost + "/admin/users/comment/" + $userdata.userId, {
            method: "GET",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => res.json()).then(json => comments = json);
    }

    function createComment() {
        if (commentInput === undefined || $userdata.userId === undefined) {
            return;
        }

        const headers = {
            "Token": Cookies.get("HDSkins-Login"),
            "Comment": commentInput
        };

        fetch(apiHost + "/admin/users/comment/" + $userdata.userId, {
            method: "POST",
            headers: headers
        }).then(res => {
            if (res.status === 200) {
                addNotification({
                    text: "Successfully added comment",
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
                loadComments();
            } else {
                addNotification({
                    text: "Failed to add comment",
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }

            commentInput = "";
        });
    }

    function removeComment(commentId) {
        if (!confirm("Do you really want to remove this comment?")) {
            return;
        }

        fetch(apiHost + "/admin/users/comment/" + commentId, {
            method: "DELETE",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            if (res.status === 200) {
                addNotification({
                    text: "Successfully removed comment",
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
                loadComments();
            } else {
                addNotification({
                    text: "Failed to remove comment",
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }
</script>

{#if $userdata.roles !== undefined}
    <Card extra="comment-card">
        <div slot="header" style="display: flex; justify-content: center">
            <h2 style="text-align: center">{$_("dashboard.comment.title")}</h2>
        </div>

        <div slot="content" class="content">
            {#each comments as comment}
                <div class="comment-wrapper">
                    <div class="comment-header">
                        <div>
                            <span style="color: white">
                                {comment.creatorName}
                            </span>
                        </div>

                        <div style="display: flex; justify-content: center">
                            <span style="color: white">
                                <Time timestamp="{comment.timestamp}" format="DD.MM.YYYY HH:mm"/>
                            </span>
                        </div>

                        <div>
                            <a class="delete-comment" on:click={() => removeComment(comment.commentId)}>
                                <img src="/assets/redx.svg" alt="Delete" width="16" height="16">
                            </a>
                        </div>
                    </div>

                    <p>{comment.text}</p>
                </div>
            {/each}
        </div>

        <div slot="footer">
            <div class="footer">
                <textarea bind:value={commentInput} class="input" minlength="1" maxlength="1000"/>

                <a on:click={createComment}>
                    <img src="/assets/plus.png" width="32" height="32" class="create-button" alt="Create"/>
                </a>
            </div>
        </div>
    </Card>
{/if}

<style>
    :global(.comment-card) {
        width: 440px;
        height: 300px;
    }

    .content {
        position: relative;
        height: 150px;
        overflow-y: auto;
        padding: 5px;
        border: 6px;
        margin: 2px;
    }

    .input {
        background-color: #2c2f33;
        border-radius: 6px;
        border: 2px white solid;
        color: white;
        outline: none;
        resize: none;
    }

    .input:active {
        background-color: #2c2f33;
        border-color: gray;
    }

    .create-button {
        float: right;
    }

    .comment-wrapper {
        width: 98%;
        height: 75px;

        margin: 3px 0;
        padding: 5px;

        border-radius: 6px;
        background-image: url("/assets/background.png");
        overflow-x: hidden;
    }

    .comment-header {
        column-count: 3;
    }

    .delete-comment {
        float: right;
    }

    .footer {
        padding: 10px;
    }

    h2, p {
        color: white;
    }
</style>
