<script>
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let width;
    export let height;

    export let background = "rgba(15, 15, 15, 0.9)";
    export let innerBackground = "#1F2937";

    export let inner = "";

    let outer;

    function handleClick(event) {
        if ((' ' + event.target.className + ' ').indexOf(' outer ') > -1) {
            dispatch("close", {});
        }
    }

</script>

<div class="outer" on:click="{handleClick}" style="background-color: {background}">
    <div class="inner {inner}" style="width: {width}; height: {height}; background-color: {innerBackground}">
        <slot/>
    </div>
</div>

<style>

    .outer {
        position: fixed;

        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        z-index: 1000;
    }

    .inner {
        margin: auto;

        border-radius: 10px;
    }

</style>
