<script>
    import {_} from "svelte-i18n";
    import Time from "svelte-time";

    export let message;
</script>

<div class="message-wrapper {message.sender.toLowerCase()}-message">
    <div class="messageinfo-wrapper {message.sender.toLowerCase()}-info">
        <span>{message.sender}</span>
        <br>
        <span><Time timestamp="{message.timestamp}" format="DD.MM.YYYY HH:mm"/></span>
    </div>

    <p class="message">{decodeURIComponent(message.message)}</p>

    {#if message.sender === "staff"}
        <div class="staff-body">
            <div>
                <img src="/favicon.png" alt="Logo" width="64">
            </div>

            <div>
                <p>{$_("appeal.greetings.title")}</p>
                <p>{$_("appeal.greetings.subTitle")}</p>
            </div>
        </div>
    {/if}
</div>


<style>
    .message-wrapper {
        border-radius: 6px;
        margin-top: 5px;
        padding: 10px;
        min-height: 40px;
    }

    .message {
        overflow-wrap: break-word;
        white-space: pre-line;
    }

    .messageinfo-wrapper {
        float: right;
        margin-left: 5px;
    }

    .messageinfo-wrapper > * {
        font-size: small;
        float: right;
    }

    .staff-message {
        background-color: rgb(156, 163, 175);
    }

    .user-message {
        background-color: rgb(107, 114, 128);
    }

    .staff-info > * {
        color: blue;
    }

    .user-info > * {
        color: lightblue;
    }

    p {
        margin: 0;
    }

    .staff-body {
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    .staff-body > * {
        font-weight: bold;
    }
</style>
