<script>
    import {_} from "svelte-i18n";
    import {link, navigate} from "svelte-routing";

    import {apiHost} from "../routes/config.js";

    import {authLevel, updateAuthLevel} from "../routes/dashboard/auth.js";

    import {getContext} from 'svelte';
    import {ROUTER} from 'svelte-routing/src/contexts';
    import {showPopup} from "../routes/popup/popup.js";
    import Cookies from "js-cookie";

    const {activeRoute} = getContext(ROUTER);

    const items = [
        {
            title: $_("navbar.team"),
            uri: "/#team",
            auth: 0
        },
        {
            title: $_("navbar.partner"),
            uri: "/#partner",
            auth: 0
        },
        {
            title: "Fabric",
            uri: "/#fabric-download",
            auth: 0
        },
        {
            title: $_("navbar.dashboard"),
            uri: "/dashboard",
            auth: 1
        },
        {
            title: $_("navbar.admin"),
            uri: "/admin",
            auth: 2
        }
    ];

    let loggingOut = false;

    function logout() {
        if (loggingOut) {
            return;
        }

        loggingOut = true;
        fetch(apiHost + "/auth/logout", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login")
            }
        }).then(res => {
            loggingOut = false;

            if (res.status === 200) {
                navigate("/");
                updateAuthLevel();
            } else {
                showPopup($_("logout.failed"), "failed", res.status);
            }
        }).catch(() => loggingOut = false);
    }

</script>


<div class="navigation">
    <a href="https://hdskins.de" class="nav-logo">
      <img src="https://hdskins.de/favicon.png" alt="" />
    </a>

    <div class="nav-items">
      {#each items as item}
        {#if $authLevel >= item.auth}
            {#if item.uri !== undefined && item.uri.indexOf("#") != -1}
                    <a
                        class="nav-item {$activeRoute != null && item.uri === $activeRoute.uri ? 'active' : ''}"
                        href="{item.uri}"
                        on:click={() => item.onclick !== undefined && item.onclick()}
                        >{item.title}
                    </a>
            {:else}
                <a
                    class="nav-item {$activeRoute != null && item.uri === $activeRoute.uri ? 'active' : ''}"
                    href="{item.uri !== undefined ? item.uri : '#'}"
                    use:link
                    on:click={() => item.onclick !== undefined && item.onclick()}
                    >{item.title}
                </a>
            {/if}
        {/if}
      {/each}

      {#if $authLevel === 0}
      <a
            class="nav-item"
            style="color: #008fe8;"
            href="https://www.labymod.net/oauth2/authorize?response_type=code&client_id=hdskins&scope=basic&redirect_uri=https://hdskins.de/dashboard/labymod/oauth"
            >{$_("navbar.labymod.login")}
      </a>
     {/if}
     {#if $authLevel >= 1}
       <a
             class="nav-item"
             style="color: #d9534f;"
             href="#"
             on:click={() => logout()}
             >{$_("navbar.logout")}
       </a>
      {/if}
    </div>
</div>


<style>
  @import url(https://fonts.googleapis.com/css?family=Lato:100);

  .navigation {
    width: 100%;
    position: relative;
    background-color: #121211;
    box-sizing: border-box;
    padding: 10px 15px 10px 15px;
    height: 68px;
  }

  .navigation .nav-logo {
    float: left;
  }

  .navigation .nav-logo img {
    width:48px;
    height:48px;
  }

  .navigation .nav-items {
    transform: translateY(25%);
    float: right;
    word-spacing: -0.2em;
    padding: 0;
  }

  .navigation .nav-item {
	position: relative;
    display: inline-block;
    background: transparent;
    margin-left: 20px;
    transition: all 200ms linear;
  }

.navigation .nav-item:hover {
  color: white !important;
}

.navigation .nav-item.active {
	color: white !important;
}


  .navigation .nav-item:after {
    position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 2px;
	content: '';
	background-color: white;
	opacity: 0;
    transition: all 200ms linear;
  }


  .navigation .nav-item:hover:after{
	bottom: 0;
	opacity: 1;
  }


  .navigation .nav-item.active:hover:after{
	opacity: 0;
  }

  .navigation a {
    text-decoration: none;
    font-size: 1.5em;
    color: gray;
    font-weight: bold;
  }

  .navigation a:hover {
    color: white;
  }
</style>
