<script>

    import SlideIn from "../animation/SlideIn.svelte";
    import {apiHost, renderHost} from "../config";
    import {_} from "svelte-i18n";

    let groups = [];

    fetch(apiHost + "/team").then(res => res.json()).then(json => {
        for (const group of json) {
            for (const member of group.member) {
                member.socialsSorted = Object.entries(member.socials);
                member.socialsSorted.sort((a, b) => a < b ? -1 : a > b ? 1 : 0);

                if (group.left === "member") {
                    member.socialsSorted.reverse();
                }
            }
        }

        groups = json;
    });

    const icons = {
        discord: "discord.svg",
        github: "github.svg",
        gommehdnet: "gommehdnet.webp",
        instagram: "instagram.svg",
        snapchat: "snapchat.svg",
        twitch: "twitch.svg",
        twitter: "twitter.svg",
        youtube: "youtube.svg",
        tiktok: "tiktok.svg"
    };

</script>

<div>

    {#each groups as group}
        <h2 style="color: {group.color}">{group.title}</h2>

        <div class="group">

            {#if group.left === "text"}
                <div class="text-wrapper">
                    <SlideIn direction="left">
                        <p class="home-page-text">{$_("home.team.text." + group.text)}</p>
                    </SlideIn>
                </div>
            {/if}

            <div class="member-wrapper">
                {#each group.member as member}
                    <div class="member" style="{group.left === 'member' ? 'justify-content: flex-end' : ''}">
                        <div class="member-info">
                            <img
                                    src="{renderHost}/users/skin/{member.uuid}/face?size=128"
                                    width="48"
                                    height="48"
                                    alt="Avatar"
                                    style="{group.left === 'member' ? 'order: 10' : ''}; padding: 5px"
                            />

                            <div>
                                <div>
                                    <a class="member-name" href="https://laby.net/@{member.name}" target="_blank">
                                        {member.name}
                                    </a>
                                </div>
                                <div
                                        class="group-name"
                                        style="background-color: {group.color}; color: {group.textColor}; {group.left === 'member' ? 'float: right' : ''}"
                                >
                                    {group.name}
                                </div>
                            </div>
                        </div>

                        {#if member.socials !== undefined}
                            <div
                                    class="social-wrapper-{group.left === 'member' ? 'left' : 'right'}"
                                    style="{group.left === 'member' ? 'order: -1' : ''}"
                            >
                                {#each member.socialsSorted as [name, url]}
                                    <a href="{url}" target="_blank">
                                        <img src="/assets/socials/{icons[name]}" width="32" alt="{name}"/>
                                    </a>
                                {/each}
                            </div>
                        {/if}
                    </div>
                {/each}
            </div>

            {#if group.left === "member"}
                <div class="text-wrapper">
                    <SlideIn direction="right">
                        <p class="home-page-text">{$_("home.team.text." + group.text)}</p>
                    </SlideIn>
                </div>
            {/if}

        </div>
    {/each}

</div>

<style>
    p, h2 {
        color: white;
        text-align: center;
    }

    .group {
        display: flex;
        margin-bottom: 15px;
    }

    .member-wrapper {
        width: 50%;

        margin-left: 10px;
        margin-right: 10px;
    }

    .text-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
    }

    .member {
        background-color: #1d1d1d;
        border-radius: 4px;

        margin-top: 3px;
        display: flex;
        align-items: center;

        padding: 1px;
    }

    .group-name {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        margin-top: 2px;

        padding: 1px 2px;

        font-size: x-small;
        font-weight: bold;
        border-radius: 3px;
    }

    .member-name {
        color: white;
        font-size: larger;
        font-weight: bold;
        cursor: pointer
    }

    .member-info {
        display: flex;
        align-items: center;
    }

    .social-wrapper-right {
        margin: auto;
        margin-right: 10px;
    }

    .social-wrapper-left {
        margin: auto;
        margin-left: 10px;
    }

    @media screen and (max-width: 750px) {
        .group {
            display: block;
        }

        .member-wrapper {
            width: 100%;
        }

        .text-wrapper {
            width: 100%;
        }
    }

</style>
