<script>

    import Saos from "saos";

    export let direction;
    export let once = true;

</script>


{#if direction === "right"}
    <Saos once={once} animation={'slide-in-right 0.5s cubic-bezier(0.35, 0.5, 0.65, 0.95) both'}>
        <slot/>
    </Saos>
{:else if direction === "left"}
    <Saos once={once} animation={'slide-in-left 0.5s cubic-bezier(0.35, 0.5, 0.65, 0.95) both'}>
        <slot/>
    </Saos>
{/if}

<style>

    .slide-in-right {
        -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /* ----------------------------------------------
     * Generated by Animista on 2021-9-30 8:31:2
     * Licensed under FreeBSD License.
     * See http://animista.net/license for more info.
     * w: http://animista.net, t: @cssanimista
     * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation slide-in-right
     * ----------------------------------------
     */
    @-webkit-keyframes -global-slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes -global-slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    /**
     * ----------------------------------------
     * animation slide-in-left
     * ----------------------------------------
     */
    @-webkit-keyframes -global-slide-in-left {
        0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes -global-slide-in-left {
        0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

</style>
