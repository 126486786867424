<script>

    import Card from "../Card.svelte";
    import BanInfo from "./BanInfo.svelte";
    import {_} from "svelte-i18n";
    import Modal from "../../Modal.svelte";
    import {apiHost} from "../../config";
    import Cookies from "js-cookie";
    import {getNotificationsContext} from 'svelte-notifications';
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();
    const {addNotification} = getNotificationsContext();

    export let userdata;
    export let admin;

    let customBan = false;
    let banReason = "";
    let banDays;

    function executeCustomBan() {
        if (banReason === "") {
            return;
        }

        let millis = undefined;
        if (banDays !== undefined && banDays != null) {
            millis = banDays * 24 * 60 * 60 * 1000;
        }

        fetch(apiHost + "/ban", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                userId: $userdata.userId,
                reason: banReason,
                time: millis !== undefined ? millis : ""
            }
        }).then(res => {
            if (res.status === 200) {
                addNotification({
                    text: "User was banned for " + banReason,
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "success"
                });
            } else {
                addNotification({
                    text: "Failed to ban user for " + banReason + " (" + res.status + ")",
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }

            banReason = "";
            banDays = null;
            customBan = false;

            refreshUser();
        });
    }

    function refreshUser() {
        dispatch("refreshUser");
    }

</script>

<Card extra="ban-info-card">

    <div slot="header" class="header">
        <h2 style="text-align: left">{$_("dashboard.ban.title")}</h2>
        {#if admin && $userdata !== undefined && !$userdata.banned}
            <button class="custom-ban-button" on:click={() => customBan = !customBan}>Custom Ban</button>
        {/if}
    </div>

    <div slot="content" class="content">

        {#if $userdata !== undefined && $userdata.bans !== undefined}
            {#each $userdata.bans as ban}

                <div class="ban">
                    <BanInfo ban={ban} admin={admin} on:refreshUser={refreshUser}/>
                </div>

            {/each}
        {/if}

    </div>

</Card>

{#if customBan}
    <Modal width="300px" height="200px" on:close={() => {customBan = false; banReason = ""; banDays = 0;}}>
        <div class="modal-content">
            <input type="text" class="input" placeholder="Reason" bind:value={banReason}>

            <input type="number" class="input" min="1" placeholder="Days" bind:value={banDays}>

            <button class="input input-button" on:click={executeCustomBan}>Ban</button>
        </div>
    </Modal>
{/if}

<style>

    .modal-content {
        margin-top: 30px;
        padding: 20px;
    }

    h2 {
        color: white;
    }

    .ban {
        border-bottom: 1px solid gray;
    }

    .content {
        overflow-y: auto;
        height: 275px;
    }

    .header {
        column-count: 2;
    }

    .custom-ban-button {
        float: right;

        background: none;
        border: 2px white solid;
        border-radius: 6px;
        color: white;
    }

    .custom-ban-button:active {
        background: none;
        border-color: gray;
    }

    .input {
        background-color: #2c2f33;
        border-radius: 6px;
        border: 2px white solid;
        color: white;
        outline: none;
        width: 100%;
    }

    .input-button:active {
        border-color: gray;
        background-color: #2c2f33;
    }

    :global(.ban-info-card) {
        width: 600px;
        height: 355px;
    }

    @media screen and (max-width: 1050px) {
        :global(.ban-info-card) {
            width: 500px;
        }
    }

    @media screen and (max-width: 550px) {
        :global(.ban-info-card) {
            width: 350px;
        }
    }

</style>
