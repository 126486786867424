<script>
    import {_} from 'svelte-i18n';
    import {getNotificationsContext} from 'svelte-notifications';
    import {apiHost} from "../config";

    const {addNotification} = getNotificationsContext();

    const options = [
        {
            id: "download",
            text: "Download"
        },
        {
            id: "render",
            text: "Render"
        }
    ];

    let username = "";
    let loading = false;

    function downloadSkin() {
        if (username.length !== 32 && username.length !== 36 && (username.length < 2 || username.length > 16)) {
            addNotification({
                text: $_("home.download.fail.notFound"),
                position: "bottom-right",
                removeAfter: 4000,
                type: "danger"
            });
            return;
        }

        loading = true;
        fetch(apiHost + "/users/" + username).then(res => {
            if (res.status === 200) {
                res.json().then(json => {
                    if (json.textures.private) {
                        addNotification({
                            text: $_("home.download.fail.private"),
                            position: "bottom-right",
                            removeAfter: 4000,
                            type: "danger"
                        });
                    } else {
                        const skin = json.textures.SKIN;
                        if (skin === undefined || skin.current === undefined) {
                            addNotification({
                                text: $_("home.download.fail.notFound"),
                                position: "bottom-right",
                                removeAfter: 4000,
                                type: "danger"
                            });
                            return;
                        }

                        window.open(apiHost + "/textures/" + skin.current.textureId, "_blank");
                    }
                })
            } else if (res.status === 404) {
                addNotification({
                    text: $_("home.download.fail.notFound"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "warning"
                });
            } else {
                addNotification({
                    text: $_("home.download.fail.error"),
                    position: "bottom-right",
                    removeAfter: 4000,
                    type: "danger"
                });
            }
        });
    }
</script>

<div style="width: 100%">

    <div>
        <p class="home-page-text">{$_("home.download.text")}</p>
    </div>

    <div style="display: table; margin: 0 auto">
        <input
                type="text"
                placeholder="{$_('home.download.name')}"
                class="input name-input"
                bind:value={username}
                on:keypress={e => e.key === "Enter" && downloadSkin()}/>
        <br>

        <!--

        <select name="type" id="type" class="input type-input">
            {#each options as option}
                <option value="{option.id}">{option.text}</option>
            {/each}
        </select>

        <br>
        -->

        <button class="input submit-input" on:click={downloadSkin}>{$_("home.download.download")}</button>
    </div>

</div>

<style>

    p {
        color: white;
        text-align: center;
    }

    .input {
        width: 300px;
        margin-top: 3px;
        text-align: center;
        border-radius: 10px;
    }

    .name-input {
        background-color: #d34200;
        color: white;
        border: none;
    }

    ::placeholder {
        color: white;
    }

    ::-ms-input-placeholder {
        color: white;
    }

    .name-input:focus, .name-input {
        outline: none;
    }

    .submit-input {
        background-color: #da4702;
        color: white;
        border: none;
        cursor: pointer;
    }

    .submit-input:active {
        background-color: #FF7200;
        outline: none;
    }

    @media screen and (max-width: 350px) {
        .input {
            width: 200px;
        }
    }

</style>
