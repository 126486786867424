<script>
    const SLOTS = $$props.$$slots;

    export let background = "#1D1D1D";

    export let extra;

    export let noWrapper = false;

    export let borders = true;
</script>

<div
        class="{noWrapper ? '' : 'content-wrapper'} {extra !== undefined ? extra : ''}"
        style="background-color: {background}"
>
    {#if SLOTS.header}
        <div class="header-wrapper">
            <div class="header {borders ? 'header-border' : ''}">
                <slot name="header"/>
            </div>
        </div>
    {/if}

    {#if SLOTS.content}
        <div class="content">
            <slot name="content"/>
        </div>
    {/if}

    {#if SLOTS.footer}
        <div class="footer-wrapper">
            <div class="footer {borders ? 'footer-border' : ''}">
                <slot name="footer"/>
            </div>
        </div>
    {/if}
</div>

<style>

    .content-wrapper {
        position: relative;

        border-radius: 6px;
        padding-left: 5px;
        padding-right: 5px;
        margin: 5px;
    }

    .header-wrapper {
    }

    .header-border {
        border-bottom: 1px gray solid;
    }

    .content {
        height: auto;
        width: 100%;
        position: relative;
    }

    .footer-wrapper {
    }

    .footer-border {
        border-top: 1px gray solid;
    }

    .footer {
        width: -moz-calc(100% - 10px);
        width: -webkit-calc(100% - 10px);
        width: calc(100% - 10px);

        position: absolute;
        bottom: 0;
    }

</style>
