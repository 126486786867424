<script>

    import {_} from "svelte-i18n";
    import {link} from "svelte-routing";
    import AppealMessage from "./AppealMessage.svelte";
    import Time from "svelte-time";

    import {apiHost} from "../../config";
    import Cookies from "js-cookie";

    import BanInfo from "../ban/BanInfo.svelte";

    let appeals = [];

    fetch(apiHost + "/appeals", {
        headers: {
            "Token": Cookies.get("HDSkins-Login")
        }
    })
        .then(res => res.json())
        .then(json => appeals = json);

</script>

<div>
    <div class="header">
        <h1>{$_("appeal.overview.title")}</h1>
    </div>

    <div>

        {#each appeals as appeal}
            <div class="entry {appeal.answerRequired ? 'entry-pending' : ''}">
                <a href="/admin/appeal?id={appeal.appealId}" use:link>
                    <button class="claim">Open</button>
                </a>

                <div>
                    <div class="user">
                        <img src="/favicon.png" alt="Head" width="48" height="48">
                        <span>{appeal.user.name} | {appeal.messages.length}</span>
                    </div>
                </div>

                <div>
                    {#if appeal.punishment !== undefined}
                        <BanInfo ban={appeal.punishment} appeal="{false}"/>
                    {/if}
                </div>
            </div>
        {/each}

    </div>

</div>

<style>
    h1 {
        color: white;
    }

    .header {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .claim {
        background-color: green;
        color: lightgray;
        outline: none;
        border: none;
        border-radius: 6px;
        float: right;
        cursor: pointer;
    }

    .claimed {
        float: right;
    }

    .claim:active {
        background-color: darkgreen;
    }

    .entry {
        background-color: rgb(156, 163, 175);
        border-radius: 6px;
        padding: 10px;
        margin-bottom: 10px;
    }

    .entry-pending {
        background-color: rgb(150, 38, 38);
    }

    .user {
        display: flex;
        align-items: center;
    }
</style>
