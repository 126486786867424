<script>
    import Card from "../Card.svelte";
    import {_} from "svelte-i18n";

    export let userdata;
</script>

<Card extra="meta-card">
    <div slot="header" style="display: flex; justify-content: center">
        <h2 style="text-align: center">{$_("dashboard.profile.meta")}</h2>
    </div>

    <div slot="content" class="content">
        {#if userdata !== undefined}
            {@html $userdata.meta}
        {/if}
    </div>
</Card>

<style>
    :global(.meta-card) {
        width: 300px;
        height: 400px;
    }

    h2, span {
        color: white;
    }

    .content {
        position: relative;
        height: 325px;
        overflow-y: auto;
    }

    :global(.meta-title) {
        text-align: center;
        color: white;
    }
</style>
