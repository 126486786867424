import {apiHost} from "../config.js";
import Cookies from "js-cookie";
import {writable} from "svelte/store";

export const authLevel = writable(0);
/*
================== Auth levels ==================

0 = no authorization
1 = user authorization
2 = staff authorization
3 = admin authorization

*/

updateAuthLevel();

export function updateAuthLevel() {
    return fetch(apiHost + "/auth/check", {
        headers: {
            "Token": Cookies.get("HDSkins-Login")
        }
    })
        .then(res => res.text())
        .then(response => authLevel.set(parseInt(response)));
}
