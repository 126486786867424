<script>
    import Navbar from './base/Navbar.svelte';
    import Footer from './base/Footer.svelte';

    import {Route, Router} from "svelte-routing";

    import Dashboard from './routes/dashboard/Dashboard.svelte';
    import Appeal from './routes/dashboard/appeal/Appeal.svelte';

    import Login from './routes/dashboard/auth/Login.svelte';
    import MissingPermission from './routes/dashboard/auth/MissingPermission.svelte';
    import TokenLogin from './routes/dashboard/auth/TokenLogin.svelte';
    import {authLevel} from './routes/dashboard/auth.js';

    import AppealStaff from './routes/dashboard/appeal/AppealStaff.svelte';
    import AppealOverview from './routes/dashboard/appeal/AppealOverview.svelte';

    import Home from './routes/home/Home.svelte';

    import Imprint from './routes/legal/Imprint.svelte';
    import Privacy from './routes/legal/Privacy.svelte';

    import Admin from './routes/admin/Admin.svelte';
    import AdminUser from './routes/admin/AdminUser.svelte';

    import NotFound from './routes/NotFound.svelte';

    import Popup from './routes/popup/Popup.svelte';
    import {hidePopup, popupBackground, popupText, popupTitle, popupType} from './routes/popup/popup.js';

    import Notifications from "svelte-notifications";
    import DiscordOAuth from "./routes/dashboard/oauth/DiscordOAuth.svelte";
    import LabyModOAuth from "./routes/dashboard/oauth/LabyModOAuth.svelte";

    let url = "";

    const routes = [
        {
            path: "/",
            component: Home,
            auth: 0
        },
        {
            path: "/imprint",
            component: Imprint,
            auth: 0
        },
        {
            path: "/privacy",
            component: Privacy,
            auth: 0
        },
        {
            path: "/login",
            component: TokenLogin,
            auth: 0
        },
        {
            path: "/dashboard",
            component: Dashboard,
            auth: 1
        },
        {
            path: "/dashboard/discord/oauth",
            component: DiscordOAuth,
            auth: 1
        },
        {
            path: "/dashboard/labymod/oauth",
            component: LabyModOAuth,
            auth: 0
        },
        {
            path: "/appeal",
            component: Appeal,
            auth: 1
        },
        {
            path: "/admin/appeals",
            component: AppealOverview,
            auth: 2
        },
        {
            path: "/admin/appeal",
            component: AppealStaff,
            auth: 2
        },
        {
            path: "/admin",
            component: Admin,
            auth: 2
        },
        {
            path: "/admin/user/:user",
            component: AdminUser,
            auth: 2
        }
    ];
</script>

<Notifications>
    <Router url={url}>
        <div class="body">
            <Navbar/>

            <div class="content-wrapper">
                {#each routes as route}
                    {#if route.auth === 0 || $authLevel >= route.auth}
                        <Route path="{route.path}" component="{route.component}"/>
                    {:else}
                        <Route path="{route.path}" component="{route.auth === 1 ? Login : MissingPermission}"/>
                    {/if}
                {/each}

                <Route component="{NotFound}"/>
            </div>

            <div style="width: 100%; height: 20px"/>

            <Footer/>

            {#if $popupTitle !== undefined}
                <Popup
                        title={$popupTitle}
                        type={$popupType}
                        text={$popupText}
                        background={$popupBackground}
                        on:close={hidePopup}
                />
            {/if}
        </div>
    </Router>
</Notifications>

<style global>
    @import "public/scrollbar.css";

    .body {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .content-wrapper {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
    }

    :global(.notifications > *) {
        z-index: 1500;
    }

    @media screen and (max-width: 500px) {
        .content-wrapper {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
</style>
