<script>

    import Time from "svelte-time";
    import {navigate} from "svelte-routing";
    import {_} from "svelte-i18n";

    import Modal from "../../Modal.svelte";
    import {apiHost, renderHost} from "../../config";
    import Cookies from "js-cookie";
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let ban;
    export let admin;
    export let appeal = true;

    let unbanning = false;
    let unbanReason = "";

    function unban() {
        fetch(apiHost + "/unban", {
            method: "POST",
            headers: {
                "Token": Cookies.get("HDSkins-Login"),
                "UnbanReason": unbanReason,
                "PunishmentId": ban.punishmentId
            }
        }).then(() => {
            unbanning = false;
            unbanReason = "";

            dispatch("refreshUser");
        });
    }

    function openAppeal() {
        if (ban.appealId !== undefined) {
            navigate("/appeal?id=" + ban.appealId);
        } else {
            navigate("/appeal?open=" + ban.punishmentId)
        }
    }

</script>

<div class="content">

    <div style="display: flex; align-items: center">
        {#if ban.textureId !== undefined}
            {#if admin}
                <a href="/admin#{ban.textureId}">
                    <img src="{renderHost}/textures/skin/{ban.textureId}/face?force&size=128" width="48" height="48">
                </a>
            {:else}
                <img src="{renderHost}/textures/skin/{ban.textureId}/face?force&size=128" width="48" height="48">
            {/if}
        {/if}

        <span class="{ban.active ? 'active-ban' : ''} ban-reason">{ban.reason}</span>

        <!--{#if (ban.appealId !== undefined || ban.active) && appeal}
            <button class="appeal" on:click="{openAppeal}">
                {$_("dashboard.ban.appeal")}
            </button>
        {/if}-->

        {#if admin}
            {#if ban.active}
                <button on:click="{() => unbanning = true}">{$_("dashboard.ban.unban")}</button>
            {/if}
        {/if}

        <div class="right">
            <span>
                <Time timestamp="{ban.timestamp}" format="DD.MM.YYYY HH:mm"/>
                <br>
                -
                {#if ban.timeout === -1}
                    permanent
                {:else}
                    <Time timestamp="{ban.timeout}" format="DD.MM.YYYY HH:mm"/>
                {/if}
            </span>
        </div>
    </div>

    {#if unbanning}
        <Modal width="300px" height="200px" on:close="{() => unbanning = false}">
            <div class="unban-content">
                <h1>{$_("dashboard.ban.unban")}</h1>
                <input type="text" placeholder="{$_('dashboard.ban.reason')}" name="reason" bind:value={unbanReason}>
                <input type="button" class="unban-button" on:click={unban} value="Unban">
            </div>
        </Modal>
    {/if}

</div>

<style>
    span, h1 {
        color: white;
    }

    .active-ban {
        color: red;
    }

    .ban-reason {
        padding-left: 5px;
    }

    .content {
        height: 50px;
        margin: auto;
    }

    .right {
        float: right;
        text-align: right;
        margin-left: auto;
    }

    button {
        background-color: #2c2f33;
        border-radius: 6px;
        border: 2px white solid;
        color: white;
    }

    input {
        background-color: #2c2f33;
        border-radius: 6px;
        color: white;
    }

    button:active {
        background-color: #2c2f33;
        border-color: gray;
    }

    .unban-content {
        margin-top: 30px;
        padding: 20px;
    }

    .unban-button {
        background-color: red;
    }
</style>
